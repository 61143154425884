import React, { useState } from "react";

const Dates = props => {
    const { datesModified } = props;
    
    return <>
        {datesModified.date_created && <div style={{textAlign:'right'}}>
            <strong className="blue-text">Date Created:</strong> {datesModified.date_created} - {datesModified.date_created_by}<br />
            <strong className="blue-text">Date Modified:</strong> {datesModified.date_modified} - {datesModified.date_modified_by}<br />
        </div>}
    </>
}

export default Dates;