import { Tab } from '@material-ui/core';
import { SettingsApplications, SettingsBackupRestore } from '@material-ui/icons';
import React from 'react';
import ReactTable from 'react-table';
import { formatDate3 } from 'shared/utility';
import moment from 'moment';

const MeetingList = (props) => {
    const { meetingsData, setMeetingData, setTab } = props;
    const data = meetingsData ? meetingsData.map((l, key) => { // map users to table rows
        const other_data = l.other_data ? 
                                (typeof l.other_data === 'string') ? 
                                    JSON.parse(l.other_data)
                                :
                                    l.other_data
                            : 
                                {invitees:[]}
        return ({
            id: l.id,
            start_datetime: l.start_datetime ? moment(l.start_datetime.replace('.000Z','')).format('MM/DD/YYYY h:mma') : '',
            invitees: other_data ? other_data.invitees.map(a => {
                return a.name;
            }).join(', ') : '',
            meetingData: l,
            outcome: l.outcome
        })
    }) : [];

    const columns = [
        {
            Header: "Meeting Start Date / Time",
            accessor: "start_datetime"
        },
        {
            Header: "Invitees",
            accessor: "invitees"
        },
        {
            Header: "Outcome",
            accessor: "outcome"
        }
    ]

    return (
        <div className="table-adjust2">
            <ReactTable 
                filterable
                className='-striped -highlight' 
                showPaginationTop 
                showPaginationBottom 
                resizable={false} 
                defaultPageSize={10} 
                pageSizeOptions={[10, 20, 30]}
                data={data} 
                columns={columns} 
                getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                      return {
                        onClick: (e) => {
                          
                          if (rowInfo) {
                            if (rowInfo.original) {
                              setMeetingData(rowInfo.original.meetingData)
                              if (props.canEdit) {
                                setTab('Edit');
                              } else {
                                setTab('View');
                              }
                            }
                          }
                        }
                      }
                    } else {
                      return {}
                    }
                  }}
            />
        </div>
    )
}

export default MeetingList;