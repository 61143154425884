import React from 'react';
import Table from "components/Table/Table.jsx";
import SortButton from "components/SortButton/SortButton.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClipLoader from 'react-spinners/ClipLoader';

const ContactsListTable = props => {
    
    const { 
        userType, // field type : agentData or contactsData
        setSort,
        sortDirection,
        sortBy
    } = props;

    

    return <div>
        {props.data[userType].length !== 0 && <Table
          tableHeaderColor="primary"
		  tableHead={[
            '#',
            <strong>First Name
				<SortButton
					setSort={setSort}
					sortBy='first_name'
					sortDirection={sortDirection}
                    currentSortBy={sortBy}
				/>
			</strong>,
            <strong>Last Name
                <SortButton
                    setSort={setSort}
                    sortBy='last_name'
                    sortDirection={sortDirection}
                    currentSortBy={sortBy}
                />
            </strong>,
            <strong>Contact Type
                <SortButton
                    setSort={setSort}
                    sortBy='contact_type'
                    sortDirection={sortDirection}
                    currentSortBy={sortBy}
                />
            </strong>,
            <strong>Companies</strong>,
            <strong>Email</strong>,
			<strong>Created
				<SortButton
					setSort={setSort}
					sortBy='created_date'
					sortDirection={sortDirection}
                    currentSortBy={sortBy}
				/>
			</strong>
        ]}
          tableData={props.dataArr}
        />}
    </div>
}

export default ContactsListTable;