import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import ReactTable from 'react-table';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { FadeLoader } from 'react-spinners';
import { formatDate3, clone } from 'shared/utility';
import AppointmentForm from "./AppointmentForm";
import { initialAppointmentData } from './AppointmentModel';
import CustomDialogWithContact from 'components/CustomDialog/CustomDialogWithContact';
import Button from '../../../../components/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import CustomDialogClose from 'components/CustomDialog/CustomDialogClose';
import { getUser } from '../../../../shared/authValidation';
import { processAppointment } from './AppointmentLogic';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import { Cached } from '@material-ui/icons';

const AppointmentMain = (props) => {
    const [tab, setTab] = useState('List');
    const [mainData, setMainData] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [openedContactModal, setOpenedContactModal] = useState(false);
    const [openedAsk, setOpenedAsk] = useState(false);
    const [user, setUser] = useState(null);
    const [userType, setUserType] = useState('own');

    useEffect(() => {
        setErrorMsg(props.error);
    }, [props.error]);

    useEffect(() => {
        setSuccessMsg(props.success);
    }, [props.success]);

    const getAppointmentsList = () => {
        if (props.contactData) {
            props.getAppointments({
                id: props.contactData.id
            });
        } else {
            props.getAppointments();
        }
    }

    useEffect(() => {
        getAppointmentsList();
        const userPerms = getUser();
        if (userPerms) {
            setUser(userPerms)
        }
    }, []);

    let allowIlliDBAccess = false;
    if (user) {
        if (user.type !== 'A') {
            allowIlliDBAccess = true;
        } else {
            if (user.permObj) {
                if (user.permObj.hasOwnProperty('CRM agent_access_illi_db') || user.permObj.hasOwnProperty('CRM agent_access_illi_db_full')) {
                    allowIlliDBAccess = true;
                }
            }
        }   
    }

    const filterSpecial = (dat) => {
        if (user) {
            if (user.type === 'A' && dat) {
                
                return dat.filter(d => {
                    if (d.user_id === 1) return false;
                    return true;
                })
            }
        }
        return dat;
    }

    const data = props.appointmentsData ? props.appointmentsData.map((l, key) => { // map users to table rows
        return ({
            id: l.id,
            contact_name: l.contact_name,
            start_datetime: l.start_datetime ? moment(l.start_datetime.replace('.000Z','')).format('MM/DD/YYYY h:mma') : '',
            end_datetime: l.end_datetime ? moment(l.end_datetime.replace('.000Z','')).format('MM/DD/YYYY h:mma') : '',
            invitees: l.other_data ? 
                l.other_data.invitees ? l.other_data.invitees.map(li => li.name).join(',') : ''
            :
                [],
            summary: l.summary,
            description: l.description,
            mainData: l
        })
    }) : [];

    const columns = [
        {
            Header: "Contact Name",
            accessor: "contact_name"
        },
        {
            Header: "Appointment Start Date / Time",
            accessor: "start_datetime"
        },
        {
            Header: "Appointment End Date / Time",
            accessor: "end_datetime"
        },
        {
            Header: "Invitees",
            accessor: "invitees"
        },
        {
            Header: "Summary",
            accessor: "summary"
        }
    ]

    let canEdit = mainData ?
            mainData.contact_access === undefined ?
                true
            :
                mainData.contact_access === 'R' ?
                    false
                :
                    true
        :
            true;

    let canDelete = mainData ?
        mainData.contact_access === undefined ?
            true
        :
            mainData.contact_access === 'R' || mainData.contact_access === 'E' ?
                false
            :
                true
    :
        true;

    // if user is Agent and current tab is illi = ReadOnly
    let readOnly = false;
    if (user) {
        if (user.type !== 'E') {
            if (userType === 'illi') {
                canDelete = false;
                canEdit = false;
                readOnly = true;
            }
        }
    }

    const showForm = ((tab === 'View' || tab === 'Add' || tab === 'Edit') && mainData) ? true : false;

    const choose = (chosen) => {
        const md = clone(initialAppointmentData);
        md.contact_id = chosen.id;
        md.contact_name = chosen.first_name + ' ' + chosen.last_name;
        setMainData(md);
        setOpenedContactModal(false);
        setTab('Add');
    }

    console.log('Appointments props', props);

    const canUpdate = (props.action === 'Edit' || !props.contactData) ?
        true
    :
        false;

    const table = <div className="table-adjust2">
        <ReactTable
            filterable
            className='-striped -highlight'
            showPaginationTop
            showPaginationBottom
            resizable={false}
            defaultPageSize={10}
            pageSizeOptions={[10, 20, 30]}
            data={data}
            columns={columns}
            getTrProps={(state, rowInfo) => {
                if (rowInfo && rowInfo.row) {
                return {
                    onClick: (e) => {
                        if (rowInfo) {
                            if (rowInfo.original) {
                                const theMainData = rowInfo.original.mainData;
                                setMainData(theMainData)
                                if (theMainData.contact_access === 'R') {
                                    setTab('View');
                                }
                                return setTab('Edit');
                            }
                        }
                    }
                }
                } else {
                return {}
                }
            }}
        />
    </div>

    return (
        <Card>
            <CardHeader color="rose" icon>
                  {canUpdate && <CardIcon
                    color={tab === 'Add' ? 'success' : 'rose'}
                    onClick={() => {
                      if (props.contactData) {
                        choose(props.contactData);
                      } else {
                        setOpenedContactModal(true);
                      }
                      setTab('Add');
                    }}
                    className="hovered hovered-shadow"
                  >
                    <i className="fas fa-plus"></i> <strong>Add Appointment</strong>
                  </CardIcon>}
            </CardHeader>
            <CardBody>
                {!props.contactData && <h1>Appointments</h1>}
                <IconButton
                    onClick={() => {
                        getAppointmentsList();
                    }}
                >
                    <Cached />
                </IconButton>

                <hr />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        {(props.loading) && <div className="text-center">
                                <div style={{margin:'0 auto',display:'inline-block'}}><FadeLoader /></div>
                            </div>}
                        {(tab === 'List' && props.appointmentsData) && <div>
                            {props.appointmentsData.length > 0 && table}
                            {(props.appointmentsData.length === 0 && !props.loading) && <div>No Appointments found</div>}
                        </div>}

                    {showForm && <Dialog
                        open={showForm}
                        keepMounted
                        maxWidth={false}
                        fullWidth={true}
                        fullScreen={true}
                    >
                      <div className="dialog-wrapper">
                        {showForm && <AppointmentForm
                            appointmentData={mainData}
                            action={readOnly ? 'View' : tab}
                            setTab={(t) => {
                                if (t === 'List') {
                                    getAppointmentsList();
                                }
                                setTab(t);
                            }}
                            canUpdate={canUpdate}
                            canEdit={canEdit}
                            canDelete={canDelete}
                            getContacts={props.getContacts}
                            contactsData={props.contactsData}
                            contactData={props.contactData}
                            setMsgsForAdd={(e) => {
                                setSuccessMsg(e.success);
                                setErrorMsg(e.error);
                            }}

                            updateAppointment={props.updateAppointment}
                            createAppointment={props.createAppointment}
                            deleteAppointment={props.deleteAppointment}
                            getAppointments={() => {
                                //props.getAppointment(mainData.id);
                            }}
                            contactID={mainData.contact_id}
                            contact_name={mainData.contact_name}
                            error={errorMsg}
                            success={successMsg}

                            setOpenedAsk={setOpenedAsk}
                            showCloseBtn={true}

                            fileList={props.fileList}
                            downloadedFile={props.downloadedFile}
                            createCrmFile={props.createCrmFile}
                            getCrmFileList={props.getCrmFileList}
                            getCrmFile={props.getCrmFile}
                            deleteCrmFile={props.deleteCrmFile}
                            contactSuccess={props.contactSuccess}
                            contactError={props.contactError}
                            contactLoading={props.contactLoading}
                            user={user}
                            setUserType={setUserType}
                        />}
                        </div>
                    </Dialog>}

                        {openedAsk && <CustomDialogClose
                            ok_go={() => {
                                setTab('List');
                            }}
                            open={openedAsk}
                            close={() => {
                                setOpenedAsk(false);
                            }}
                        />}
                    </GridItem>
                </GridContainer>
            </CardBody>

            {openedContactModal && <CustomDialogWithContact
                open={openedContactModal}
                close={() => {
                    setOpenedContactModal(false);
                }}
                create_and_choose={choose}
                choose={choose}
                title="Search Existing Contact"
                label="Search Existing Contact"
            />}
        </Card>
    )
}

const mapStateToProps = state => {
    const p = state.crm_appointments;
    const c = state.crm_contacts;
    return {
        error: p.error,
        success: p.success,
        loading: p.loading,
        contactsData: c.contactsData,
        appointmentsData: p.appointmentsData,
        appointmentData: p.appointmentData,
        loading: p.loading,
        contactNames: p.contactNames,
        fileList: c.fileList,
        downloadedFile: c.downloadedFile,
        contactError: c.error,
        contactSuccess: c.success,
        contactLoading: c.loading
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
        getAppointments: (contact_id) => {
            dispatch(actions.getAppointments(contact_id));
        },
        getAppointment: (id) => {
            dispatch(actions.getAppointment(id));
        },
        createAppointment: (data) => {
            dispatch(actions.createAppointment(data));
        },
        updateAppointment: (data) => {
            dispatch(actions.updateAppointment(data));
        },
        deleteAppointment: (id) => {
            dispatch(actions.deleteAppointment(id));
        },
        getContacts: (params) => {
            dispatch(actions.getContacts(params));
        },
        getContactNames: () => {
            dispatch(actions.getContactNames());
        },
        getCrmFileList: (data) => {
            dispatch(actions.getCrmFileList(data));
        },
        createCrmFile: (data) => {
            dispatch(actions.createCrmFile(data));
        },
        getCrmFile: (data) => {
            dispatch(actions.getCrmFile(data));
        },
        deleteCrmFile: (data) => {
            dispatch(actions.deleteCrmFile(data));
        }
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentMain);