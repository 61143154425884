import React, { useEffect, useState, useRef } from "react";
import { Cached } from "@material-ui/icons";
import { Dialog } from "@material-ui/core";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import { connect } from 'react-redux';
import { getUser } from "../../../shared/authValidation";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomRadio from "components/CustomRadio/CustomRadio";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import SortButton from "components/SortButton/SortButton.jsx";
import CustomDialogClose from "components/CustomDialog/CustomDialogClose";

import PropertiesAskModal from "./PropertiesAskModal";
import PropertiesForm from "./PropertiesForm";
import propertyFields from "./PropertiesObj";
import NotificationFixedTop from "../../../components/Notification/NotificationFixedTop";
import { STATUS } from "../../Listing/ListingObj";
import { getStatusType } from "../../ERTL/FormWizard/ERTLModel";
import { statusDescriptions } from "../../CommissionSplits/CommissionSplitsLogic";
import { statusDescriptions as rsheetStatusDescriptions } from "../../RSheets/RSheetsLogic";
import { pmaStatus } from "../../PMA/PMAObj";
import { get } from "lodash";
import checkPermissions from "shared/checkPermissions";

const checkPropertyDocuments = (propertyDocuments) => {
    console.log('checking property documents', propertyDocuments);
    const obj = {
        listings: null,
        ertl: null,
        csplits: null,
        rs: null,
        pma: null
    }
    if (propertyDocuments) {
        const listings = get(propertyDocuments, 'listings', []);
        if (listings.length > 0) {
            const last_listing = listings[listings.length - 1];
            obj.listings = {
                status: STATUS[last_listing.status] ? STATUS[last_listing.status] : null,
                id: last_listing.id
            }
        }
        const ertls = get(propertyDocuments, 'ertls', []);
        if (ertls.length > 0) {
            const last_ertl = ertls[ertls.length - 1];
            obj.ertl = {
                status: getStatusType(last_ertl.status) ? getStatusType(last_ertl.status) : null,
                id: last_ertl.id
            }
        }
        const csplits = get(propertyDocuments, 'csplits', []);
        if (csplits.length > 0) {
            const last_csplit = csplits[csplits.length - 1];
            obj.csplits = {
                status: statusDescriptions[last_csplit.status] ? statusDescriptions[last_csplit.status] : null,
                id: last_csplit.id
            }
        }
        const rs = get(propertyDocuments, 'rsheets', []);
        if (rs.length > 0) {
            const last_rs = rs[rs.length - 1];
            obj.rs = {
                status: rsheetStatusDescriptions[last_rs.status] ? rsheetStatusDescriptions[last_rs.status] : null,
                id: last_rs.id
            }
        }
        const pmas = get(propertyDocuments, 'pma', []);
        if (pmas.length > 0) {
            const last_pma = pmas[pmas.length - 1];
            obj.pma = {
                status: pmaStatus[last_pma.status] ? pmaStatus[last_pma.status] : null,
                id: last_pma.id
            }
        }
    }
    console.log('checking property documents o', obj);
    return obj;
}

const Properties = (props) => {
    const [propertiesArr, setPropertiesArr] = useState([]);
    const [openedForm, setOpenedForm] = useState(false);
    const [openedAskModal, setOpenedAskModal] = useState(false);
    const [propertyData, setPropertyData] = useState(null);
    const [action, setAction] = useState("Edit");
    const [openedAsk, setOpenedAsk] = useState(false);
    const [userType, setUserType] = useState('data');
    const [userPerms, setUserPerms] = useState(null);
    const [notification, setNotification] = useState('');
    const [btnPressed, setBtnPressed] = useState('');

    function usePrevious(value) {
		const ref = useRef();
		useEffect(() => {
			ref.current = value;
		});
		return ref.current;
	}

	const prevPropertyData = usePrevious(props.propertyData);

    useEffect(() => {
        const user = getUser();
        if (user) {
            setUserPerms(user);
            if (user.type === 'A') {
                setUserType('agentData');
            }
        }
        props.getProperties();
    }, []);

    useEffect(() => {
        // Re-fetch the list when closing the form
        if (!openedForm) {
            const user = getUser();
            if (user) {
                setUserPerms(user);
                if (user.type === 'A') {
                    setUserType('agentData');
                }
            }
            props.getProperties();
        }
    }, [openedForm])

    useEffect(() => {
        
        if (props.propertiesData) {
            const propertiesData = props.propertiesData[userType];
            if (propertiesData) {
                if (propertiesData.length > 0) {
                    setPropertiesArr(
                        propertiesData.map((p, i) => {
                            const r1 = p.street_range1;
                            const r2 = p.street_range2;
                            const range = r1 && r2 ? `${r1} - ${r2}` : '';

                            const r21 = p.street2_range1;
                            const r22 = p.street2_range2;
                            const range2 = r21 && r22 ? `${r21} - ${r22}` : '';
                            return [
                                // offset + (i + 1),
                                <div
                                    className="hoverable"
                                    onClick={() => {
                                        openForm("View", p);
                                    }}
                                >
                                    {p.illi_number}
                                </div>,
                                <div
                                    className="hoverable"
                                    onClick={() => {
                                        openForm("View", p);
                                    }}
                                >
                                    {p.name}
                                </div>,
                                <div>
                                    {p.username}
                                </div>,
                                <div
                                    className="hoverable"
                                    onClick={() => {
                                        openForm("View", p);
                                    }}
                                >
                                    {range} {p.street1}, {range2} {p.street2} {p.city} {p.state}
                                </div>,
                                <div
                                    className="hoverable"
                                    onClick={() => {
                                        openForm("View", p);
                                    }}
                                >
                                    {p.use_type}
                                </div>,
                                p.illi_represented === 1 ? "Yes" : "No",
                                moment(p.modified_date).format("MM/DD/YYYY")
                            ];
                        })
                    );
                    return;
                }
            }
        }
        return setPropertiesArr([]);
    }, [props.propertiesData]);

    useEffect(() => {
        // Loads a previously queried Added property
        if (prevPropertyData !== props.propertyData && props.propertyData) {
            const url_arr = document.URL.split('?');
            if (url_arr.length > 1) {
                const url = `?${url_arr[1]}`;
                const params = new URLSearchParams(url);
                const property_id = params.get('property_id');
                if (property_id) {
                    if (parseInt(property_id) === props.propertyData.id) {
                        openForm('Edit', props.propertyData);
                        history.replaceState(null, null, "/crm/properties");
                        setNotification('Successfully Added Property');
                    }
                }
            }
        }

    }, [props.propertyData]);

    useEffect(() => {
        const url_arr = document.URL.split('?');
        if (url_arr.length > 1) {
            const url = `?${url_arr[1]}`;
            const params = new URLSearchParams(url);
            const property_id = params.get('property_id');
            if (property_id) {
                console.log('got property id', property_id);
                props.getProperty(property_id);
            }
        }
    }, []);

    const { rows, offset } = props;
    const page_num = Math.ceil((offset + 1) / rows);
    const count = props.propertiesData ? props.propertiesData[userType === 'data' ? 'count' : 'agentDataCount'] : 0;
    const page_count = Math.ceil(count / rows);
    const pages_links = isNaN(page_count)
        ? null
        : Array(page_count)
                .fill()
                .map((element, index) => index + 1)
                .map((num) => {
                    if (page_count > 10) {
                        const four = page_num + 4;
                        const four2 = page_num - 4;
                        if (num > four || num < four2) {
                            return null;
                        }
                    }
                    return (
                        <span
                            onClick={() => {
                                props.setOffset((num - 1) * rows);
                            }}
                            className={
                                num === page_num ? "tiny-box current" : "hoverable tiny-box"
                            }
                            key={num}
                            style={{
                                marginRight: -1,
                            }}
                        >
                            {num}
                        </span>
                    );
                });
    const showBack = page_num === 1 ? false : true;
    const showForward = page_num === page_count ? false : true;

    const [tab, setTab] = useState('Main');

    const renderNav = () => {
        return (
            <div className="text-center">
                {propertiesArr.length > 0 && (
                    <p>
                        Showing {offset + 1} to {offset + propertiesArr.length} of {count ? count.toLocaleString() : count}{" "}
                        property(s)
                    </p>
                )}
                {propertiesArr.length > 0 && (
                    <div style={{ marginTop: 20 }}>
                        <span
                            onClick={() => {
                                props.setOffset(0);
                            }}
                            style={{borderRight: !showBack ? '1px solid #ccc' : '0'}}
                            className="hoverable tiny-box">
                            First
                        </span>
                        <span
                            onClick={() => {
                                if (showBack) {
                                    props.setOffset((page_num - 1 - 1) * rows);
                                }
                            }}
                            style={{ opacity: showBack ? 1 : 0.2, marginRight: -1 }}
                            className={showBack ? "hoverable tiny-box" : "tiny-box"}
                        >
                            Previous
                        </span>
                        {pages_links}
                        {page_count > 10 ? ' . . . ' : ''}
                        <span
                            onClick={() => {
                                if (showForward) {
                                    props.setOffset((page_num + 1 - 1) * rows);
                                }
                            }}
                            style={{ opacity: showForward ? 1 : 0.2, marginRight: -1 }}
                            className={showForward ? "hoverable tiny-box" : "tiny-box"}
                        >
                            Next
                        </span>
                        <span
                            onClick={() => {
                                props.setOffset((page_count - 1) * rows);
                            }}
                            style={{borderLeft:'1px solid #ccc'}}
                            className="hoverable tiny-box">
                            Last
                        </span>
                    </div>
                )}
            </div>
        );
    };

    const openForm = (action, propertyData) => {
        setAction(action);
        setPropertyData(propertyData);
        setOpenedForm(true);
    };

    const openAskModal = (propertyData) => {
        setPropertyData(propertyData);
        setOpenedAskModal(true);
    };

    let allowIlliDBAccess = false;
    if (userPerms) {
        if (userPerms.type !== 'A') {
            allowIlliDBAccess = true;
        } else {
            if (userPerms.permObj) {
                if (userPerms.permObj.hasOwnProperty('CRM agent_access_illi_db') || userPerms.permObj.hasOwnProperty('CRM agent_access_illi_db_full')) {
                    allowIlliDBAccess = true;
                }
            }
        }   
    }

    console.log('propertyDocuments', props);

    const propertyDocumentsCheck = checkPropertyDocuments(props.property_documents);
    const allPermissions = {
        ertl: checkPermissions("ERTL"),
        pma: checkPermissions("PMA"),
        listing: checkPermissions("Listing"),
        rs: checkPermissions("Routing"),
        csplits: checkPermissions("CommissionSplits")
    }

    const propertyDocuments = <div className="bottom-dweller" style={{display:'none'}}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>

                    {/* LISTING */}
                    {!propertyDocumentsCheck.listings && <Button
                        className="mr-20"
                        disabled={allPermissions.listing ? false : true}
                        onClick={() => {
                            window.location.href = `/listing?p=new&property_id=${propertyData ? 
                                propertyData.id ? propertyData.id : '' : ''}`;
                        }}
                    >Send New Listing Submission Form &nbsp;<i className="fa fa-chevron-right"></i></Button>}
                    {propertyDocumentsCheck.listings && <Button
                        className="mr-20"
                        disabled={allPermissions.listing ? false : true}
                        onClick={() => {
                            window.location.href = `/listing?p=open&listing_id=${propertyDocumentsCheck.listings.id}`;
                        }}
                    >New Listing Submission Sent - {propertyDocumentsCheck.listings.status} &nbsp;<i className="fa fa-chevron-right"></i></Button>}

                    {/* ERTL  */}
                    {!propertyDocumentsCheck.ertl && <Button
                        className="mr-20"
                        disabled={allPermissions.ertl ? false : true}
                        onClick={() => {
                            window.location.href = `/ertl?p=new&property_id=${propertyData ? 
                                propertyData.id ? propertyData.id : '' : ''}`;
                        }}
                    >Create ERTL</Button>}
                    {propertyDocumentsCheck.ertl && <Button
                        className="mr-20"
                        disabled={allPermissions.ertl ? false : true}
                        onClick={() => {
                            window.location.href = `/ertl?p=open&ertl_id=${propertyDocumentsCheck.ertl.id}`;
                        }}
                    >ERTL Created - {propertyDocumentsCheck.ertl.status} &nbsp;<i className="fa fa-chevron-right"></i></Button>}

                    {/* COMMISSION SPLITS  */}
                    {!propertyDocumentsCheck.csplits && <Button
                        className="mr-20"
                        disabled={allPermissions.csplits ? false : true}
                        onClick={() => {
                            window.location.href = `/commission_splits?p=new&property_id=${propertyData ? 
                                propertyData.id ? propertyData.id : '' : ''}`;
                        }}
                    >Create Commission Splits</Button>}
                    {propertyDocumentsCheck.csplits && <Button
                        className="mr-20"
                        disabled={allPermissions.csplits ? false : true}
                        onClick={() => {
                            window.location.href = `/commission_splits?p=open&cs_id=${propertyDocumentsCheck.csplits.id}`;
                        }}
                    >Created Commission Splits - {propertyDocumentsCheck.csplits.status} &nbsp;<i className="fa fa-chevron-right"></i></Button>}

                    {/* ROUTING SHEETS  */}
                    {!propertyDocumentsCheck.rs && <Button
                        disabled={allPermissions.rs ? false : true}
                        className="mr-20"
                        onClick={() => {
                            window.location.href = `/rs?p=new&property_id=${propertyData ? 
                                propertyData.id ? propertyData.id : '' : ''}`;
                        }}
                    >Create Routing Sheet</Button>}
                    {propertyDocumentsCheck.rs && <Button
                        className="mr-20"
                        disabled={allPermissions.rs ? false : true}
                        onClick={() => {
                            window.location.href = `/rs?p=open&rs_id=${propertyDocumentsCheck.rs.id}`;
                        }}
                    >ROUTING SHEET - {propertyDocumentsCheck.rs.status} &nbsp;<i className="fa fa-chevron-right"></i></Button>}

                    {/* PMA  */}
                    {!propertyDocumentsCheck.pma && <Button
                        className="mr-20"
                        onClick={() => {
                            window.location.href = `/pma?p=new&property_id=${propertyData ? 
                                propertyData.id ? propertyData.id : '' : ''}`;
                        }}
                    >Create PMA</Button>}
                    {propertyDocumentsCheck.pma && <Button
                        className="mr-20"
                        disabled={true}
                        onClick={() => {
                           window.location.href = `/pma?p=open&pma_id=${propertyDocumentsCheck.pma.id}`
                        }}
                    >PMA - {propertyDocumentsCheck.pma.status} &nbsp;<i className="fa fa-chevron-right"></i></Button>}
                </GridItem>
            </GridContainer>
        </div>

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    {!openedForm && (
                        <div>
                            <div className="illi-table">
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        props.getProperties(true);
                                    }}
                                >
                                    <GridContainer>
                                        <GridItem xs={12} sm={2} md={2}>
                                            <h3 style={{ display: "inline-block" }}>Properties</h3><br />
                                            <Button
                                                size="sm"
                                                onClick={() => {
                                                    props.resetFilters();
                                                    setTimeout(() => {
                                                        props.getProperties();
                                                    }, 500);
                                                }}
                                                color="info"
                                            >
                                                <Cached /> Clear Search
                                            </Button>
                                        </GridItem>
                                        <GridItem xs={12} sm={2} md={2}>
                                            <CustomInput
                                                label="Search by Name"
                                                value={props.filters.name}
                                                onChange={(e) => {
                                                    props.setFilters("name", e.target.value);
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={2} md={2}>
                                            <CustomInput
                                                label="Search by illi Number"
                                                value={props.filters.illi_number}
                                                onChange={(e) => {
                                                    props.setFilters("illi_number", e.target.value);
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={2} md={2}>
                                            <CustomInput
                                                label="Search by Address"
                                                value={props.filters.street1}
                                                onChange={(e) => {
                                                    props.setFilters("street1", e.target.value);
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={2} md={2}>
                                            <CustomInput
                                                label="Search by City"
                                                value={props.filters.city}
                                                onChange={(e) => {
                                                    props.setFilters("city", e.target.value);
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={2} md={2}>
                                            <CustomSelect
                                                label="Search by Company Type"
                                                options={propertyFields.use_type.options}
                                                choose={(e, n) => {
                                                    props.setFilters("use_type", e);
                                                }}
                                                default={props.filters.use_type}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={3} md={3}></GridItem>
                                        <GridItem xs={12} sm={2} md={2}></GridItem>
                                        <GridItem xs={12} sm={3} md={3}>
                                            <div style={{ marginTop: 20 }}>
                                                <CustomRadio
                                                    label="Search by illi Represented,Yes,No,All"
                                                    options={["1", "0", ""]}
                                                    onChange={(e) => {
                                                        props.setFilters(
                                                            "illi_represented",
                                                            e.target.value
                                                        );
                                                    }}
                                                    value={props.filters.illi_represented}
                                                />
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={1} md={1}>
                                            <Button
                                                color="warning"
                                                type="submit"
                                                className="outlined-btn"
                                            >
                                                SEARCH
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </form>
                            {userPerms && <div>
                                {userPerms.type === 'A' && <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        {allowIlliDBAccess && <Button
                                            style={{marginRight:20}}
                                            color={userType === 'data' ? 'primary' : 'white'}
                                            onClick={() => {
                                                props.setOffset(0);
                                                props.getProperties();
                                                setUserType('data');
                                            }}
                                        >illi Properties</Button>}
                                        <Button
                                            color={userType !== 'data' ? 'primary' : 'white'}
                                            onClick={() => {
                                                props.setOffset(0);
                                                props.getProperties();
                                                setUserType('agentData');
                                            }}
                                        >Your Properties</Button>
                                        <hr />
                                    </GridItem>
                                </GridContainer>}
                            </div>}
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <hr />
                                        {renderNav()}
                                    </GridItem>
                                </GridContainer>
                                {!props.loading && <Table
                                    tableHeaderColor="primary"
                                    tableHead={[
                                        // "#",
                                        <strong>
                                            illi #
                                            <SortButton
                                                setSort={props.setSort}
                                                sortBy="illi_number"
                                                currentSortBy={props.sortBy}
                                                sortDirection={props.sortDirection}
                                            />
                                        </strong>,
                                        <strong>
                                            Name
                                            <SortButton
                                                setSort={props.setSort}
                                                sortBy="name"
                                                currentSortBy={props.sortBy}
                                                sortDirection={props.sortDirection}
                                            />
                                        </strong>,
                                        <strong>
                                            Owner
                                        </strong>,
                                        <strong>
                                            Address
                                            <SortButton
                                                setSort={props.setSort}
                                                sortBy="street1"
                                                currentSortBy={props.sortBy}
                                                sortDirection={props.sortDirection}
                                            />
                                        </strong>,
                                        <strong>
                                            Company Type
                                            <SortButton
                                                setSort={props.setSort}
                                                sortBy="use_type"
                                                currentSortBy={props.sortBy}
                                                sortDirection={props.sortDirection}
                                            />
                                        </strong>,
                                        <strong>illi Represented</strong>,
                                        <strong>
                                            Modified
                                            <SortButton
                                                setSort={props.setSort}
                                                sortBy="modified_date"
                                                currentSortBy={props.sortBy}
                                                sortDirection={props.sortDirection}
                                            />
                                        </strong>
                                    ]}
                                    tableData={propertiesArr}
                                    coloredColls={[2]}
                                    colorsColls={["primary"]}
                                />}
                            </div>
                            {props.loading && <div className="text-center">
                                    <ClipLoader />
                                </div>}
                            {(propertiesArr.length === 0 && !props.loading) && (
                                <div
                                    className="text-center"
                                    style={{ marginTop: 20, marginBottom: 20 }}
                                >
                                    <em>No Properties</em>
                                </div>
                            )}
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    {count > 9 && (
                                        <div style={{ marginTop: 20, marginBottom: 20 }}>
                                            {renderNav()}
                                        </div>
                                    )}
                                </GridItem>
                                <GridItem xs={12} sm={3} md={3}>
                                    <CustomSelect
                                        label="Rows per page"
                                        options={[10, 15, 20, 25, 30]}
                                        choose={(e, n) => {
                                            props.setRows(e);
                                        }}
                                        default={props.rows}
                                    />
                                </GridItem>
                            </GridContainer>
                        </div>
                    )}
                    {openedForm && (
                        <Dialog
                            open={openedForm}
                            keepMounted
                            maxWidth={false}
                            fullWidth={true}
                            fullScreen={true}
                        >
                            <div className="dialog-wrapper">
                                <PropertiesForm
                                    action={action}
                                    propertyData={propertyData}
                                    setMessage={props.setMessage}
                                    setAction={setAction}
                                    error={props.error}
                                    success={props.success}
                                    updateProperty={props.updateProperty}
                                    getProperties={props.getProperties}
                                    rows={props.rows}
                                    offset={props.offset}
                                    filters={props.filters}
                                    loading={props.loading}
                                    contactLoading={props.contactLoading}
                                    closeForm={() => {
                                        setOpenedForm(false);
                                        setTab('Main');
                                    }}
                                    openAskModal={openAskModal}
                                    openForm={openForm}
                                    getContacts={props.getContacts}
                                    contactsData={props.contactsData}
                                    getContact={props.getContact}
                                    contactData={props.contactData}
                                    createProperty={props.createProperty}
                                    clearMessages={props.clearMessages}
                                    getContactNames={props.getContactNames}
                                    contactNames={props.contactNames}
                                    clearContactNames={props.clearContactNames}
                                    getPropertyShares={props.getPropertyShares}
                                    getPropertyUsernames={props.getPropertyUsernames}
                                    createPropertyShare={props.createPropertyShare}
                                    deletePropertyShare={props.deletePropertyShare}
                                    propertyShares={props.propertyShares}
                                    propertyUsernames={props.propertyUsernames}
                                    fileList={props.fileList}
                                    getCrmFileList={props.getCrmFileList}
                                    getCrmFile={props.getCrmFile}
                                    deleteCrmFile={props.deleteCrmFile}
                                    downloadedFile={props.downloadedFile}
                                    createCrmFile={props.createCrmFile}
                                    btnPressed={btnPressed}
                                    openedAsk={openedAsk}
                                    setOpenedAsk={setOpenedAsk}
                                    showCloseBtn={true}
                                    contactError={props.contactError}
                                    contactSuccess={props.contactSuccess}
                                    checkPropertyExistingAddress={
                                        props.checkPropertyExistingAddress
                                    }
                                    existingAddress={props.existingAddress}
                                    tab={tab}
                                    setTab={setTab}
                                    getPropertyUseType={props.getPropertyUseType}
                                    propertyUseTypes={props.propertyUseTypes}
                                    propertyDocuments={propertyDocuments}
                                />
                            </div>

                            
                        </Dialog>
                    )}
                    {openedAskModal && (
                        <PropertiesAskModal
                            open={openedAskModal}
                            close={() => {
                                setOpenedAskModal(false);
                            }}
                            propertyData={propertyData}
                            deleteProperty={props.deleteProperty}
                            getProperties={props.getProperties}
                            clearMessages={props.clearMessages}
                            success={props.success}
                            error={props.error}
                        />
                    )}
                    {openedAsk && (
                        <CustomDialogClose
                            ok_go={() => {
                                setOpenedForm(false);
                            }}
                            open={openedAsk}
                            close={(btn) => {
                                setBtnPressed(btn)
                                setOpenedAsk(false);
                            }}
                            component="Property"
                            ok_save={() => {
                                setTab("Submit");
                            }}
                        />
                    )}
                    {notification && <NotificationFixedTop 
                        open={notification ? true : false}
                        close={() => {
                            setNotification('');
                        }}
                        message={notification}
                        no_ok_button={true}
                        autoClose={5000}
                    />}
                </GridItem>
            </GridContainer>
        </div>
    );
};

const mapStateToProps = state => {
	return {
	  property_documents: state.crm_properties.property_documents
    }
}
  
export default connect(mapStateToProps, null)(Properties);
