import axios from 'store/axios/axios-crm';
import { formatDate2, handleError } from './utility';

export const historyQuery = {
    get: async (data) => {
        const types = {
            leases: 1,
            listings: 2,
            meetings: 3,
            offers: 4,
            showings: 5,
        };
        let historydObj = {
            date_created: '',
            date_created_by: '',
            date_modified: '',
            date_modified_by: ''
        };
        try {
            const historyd = await axios.get(`/crm_sub_history?property_id=${data.property_id}&type=${types[data.type_name]}`);
            console.log('history', historyd)
            if (historyd.data.length > 0) {
                for (let i = 0; historyd.data.length; i++) {
                    const histd = historyd.data[i];
                    if (i === 0) {
                        historydObj.date_created = formatDate2(histd.timestamp);
                        historydObj.date_created_by = histd.name;
                    }
                    historydObj.date_modified = formatDate2(histd.timestamp);
                    historydObj.date_modified_by = histd.name;
                }
            }
        } catch(e) {
            handleError(e);
        }
        return historydObj;
    }
}