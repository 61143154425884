import React, { useEffect, useState, useRef } from "react";
import { connect } from 'react-redux';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Datetime from "react-datetime";
import Delete from "@material-ui/icons/Delete";
import Person from "@material-ui/icons/Person";
import Button from "components/CustomButtons/Button.jsx";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBack from "@material-ui/icons/ArrowBack";
import meetingFields, { initialMeetingData } from "./MeetingModel";
import {
	formatDate4,
	formatDateForBackend,
	capitalize,
	formatTime2,
	clone
} from "shared/utility";
import CustomDialog from "components/CustomDialog/CustomDialogWithContact";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MeetingModal from "./MeetingModal";
import TextField from "@material-ui/core/TextField";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Time from "../../../../components/Time/Time";
import moment from "moment";
import Close from "@material-ui/icons/Close";
import FilesForm from "./FilesForm";
import { Tabs, Tab } from "@material-ui/core";
import { getUser } from "../../../../shared/authValidation";
import {
	min1,
	min6,
	min4,
	min10,
	email,
	required,
	no_letters,
	no_special_chars,
	no_numbers,
	max4,
	max10,
	max15,
	max50,
	max100,
	max250,
	max500,
} from "shared/validation";
import Loader from "../../../../components/Loader/Loader";
import Notification from "../../../../components/Notification/Notification";
import { checkDateOrder2, createTheContactName } from "../../../../shared/utility";
import { historyQuery } from "../../../../shared/historyQuery";
import Dates from "../../shared/Dates";
const validate = {
	min1,
	min6,
	min4,
	min10,
	email,
	required,
	no_letters,
	no_special_chars,
	no_numbers,
	max4,
	max10,
	max15,
	max50,
	max100,
	max250,
	max500,
};

const getValidations = (f) => {
	return meetingFields[f].validations.map((v) => validate[v]);
};
const MeetingForm = (props) => {
	const {
		meetingData,
		action,
		setTab,
		canEdit,
		canDelete,
		setMsgsForAdd,
		createMeeting,
		updateMeeting,
		propertyID,
		getMeetings,
		deleteMeeting,
		error,
		success,
		setUserType
	} = props;

	const [data, setData] = useState(initialMeetingData);
	const [formDataObj, setFormDataObj] = useState(null);
	const [openedSearchModal, setOpenedSearchModal] = useState(false);
	const [openedModal, setOpenedModal] = useState(false);
	const [peopleType, setPeopleType] = useState(null);
	const [people, setPeople] = useState([]);
	const [errorMsg, setErrorMsg] = useState("");
	const [successMsg, setSuccessMsg] = useState("");
	const [madeChanges, setMadeChanges] = useState(false);
	const [curTab, setCurTab] = useState("Info");
	const [openTime, setOpenTime] = useState('');
	const [notification, setNotification] = useState('');
	const [datesModified, setDatesModified] = useState({
			date_created: '',
			date_created_by: '',
			date_modified: '',
			date_modified_by: ''
		});

	const updateDataObj = (field, value) => {
		const newData = clone(data);
		newData[field] = value;
		setData(newData);
		setMadeChanges(true);
	};

	const updateDataObjs = (obj) => {
		const newData = clone(data);
		Object.keys(obj).forEach(field => {
			newData[field] = obj[field];
		})
		setData(newData);
		setMadeChanges(true);
	};

	const cleanData = (data) => {
		const newMeetingData = { ...data };
		newMeetingData.start_datetime = formatDateForBackend(
			newMeetingData.start_date,
			newMeetingData.start_time
		);
		delete newMeetingData.start_date;
		delete newMeetingData.start_time;

		newMeetingData.end_datetime = formatDateForBackend(
			newMeetingData.end_date,
			newMeetingData.end_time
		);
		delete newMeetingData.end_date;
		delete newMeetingData.end_time;

		if (newMeetingData.date_created) {
			delete newMeetingData.date_created;
		}
		if (newMeetingData.date_modified) {
			delete newMeetingData.date_modified;
		}
		if (newMeetingData.other_data) {
			newMeetingData.other_data = newMeetingData.other_data;
		}
		if (formDataObj) {
			newMeetingData["formDataObj"] = formDataObj;
		}
		return newMeetingData;
	};

	const checkDates = (data) => {
		let passed = true;
		if (data.start_date === data.end_date && data.start_time === data.end_time) {
			return false;
		}
		// TO DO : Check if end is after start
		return passed;
	}

	const doUpdate = () => {
		const passed = checkDates(data);
		if (passed) {
			const newData = clone(data);
			updateMeeting(cleanData(newData));
			setMadeChanges(false);
		} else {
			setNotification('Please check your dates and times for validity.');
		}
	};

	const doAdd = () => {
		const newData = clone(data);
		delete newData.id;
		newData.property_id = propertyID;
		newData.property_name = props.property_name;
		createMeeting(cleanData(newData));
		setMadeChanges(false);
	};

	function usePrevious(value) {
		const ref = useRef();
		useEffect(() => {
			ref.current = value;
		});
		return ref.current;
	}

	const prevError = usePrevious(error);
	const prevSuccess = usePrevious(success);

	useEffect(() => {
		if (prevError === null && error) {
			setErrorMsg(error);
		}
	}, [error]);

	useEffect(() => {
		
		if (prevSuccess !== success && success && prevSuccess !== undefined) {
			setSuccessMsg(success);
			if (action === "Add") {
				setData({ ...initialMeetingData });
				getMeetings();
				setTab("List");
			}
			if (success.indexOf("Deleted") !== -1) {
				setOpenedModal(false);
				getMeetings();
				setTab("List");
			}
		}
	}, [success]);

	useEffect(() => {
		if (data) {
			let errors = "";
			["start_date", "end_date"].forEach((d) => {
				const dFormatted = capitalize(d.toLowerCase().split("_").join(" "));
				if (data[d]) {
					const isRealDate = moment(data[d], "MM/DD/YYYY", true).isValid();
					if (!isRealDate) {
						errors += `${dFormatted} must be a valid MM/DD/YYYY formatted date. `;
					}
				}
				if (d === "start_date" || d === "end_date") {
					if (!data[d]) {
						errors += `${dFormatted} is required. `;
					}
				}
			});
			if (data.start_date && data.end_date) {
				if (!checkDateOrder2(data)) {
					errors += `Invalid date range. Start Date/Time must be before End Date/Time`;
				}
			}
			setErrorMsg(errors);
		}
	});

	useEffect(() => {
		if (meetingData && (action === "Edit" || action === "View")) {
			const newMeetingData = clone(meetingData);
			// reformat to MM/DD/YYYY
			if (newMeetingData["start_datetime"]) {
				newMeetingData.start_date = formatDate4(newMeetingData.start_datetime.replace('.000Z',''));
				newMeetingData.start_time = formatTime2(newMeetingData.start_datetime.replace('.000Z',''), 'no_utc');
			}
			if (newMeetingData["end_datetime"]) {
				newMeetingData.end_date = formatDate4(newMeetingData.end_datetime.replace('.000Z',''));
				newMeetingData.end_time = formatTime2(newMeetingData.end_datetime.replace('.000Z',''), 'no_utc');
			}
			if (meetingData.invitees) {
				newMeetingData.other_data = {
					invitees: clone(meetingData.invitees)
				};
				delete meetingData.invitees;
			}
			setData(newMeetingData);
		} else if (meetingData && action === "Add") {
			setData({ ...initialMeetingData });
			setSuccessMsg("");
		}
	}, [action]);

	const displayCreatedModified = async (property_id) => {
			const hist = await historyQuery.get({
				property_id,
				type_name: 'meetings'
			});
			if (hist) {
				setDatesModified(hist);
			}
			console.log('hist', hist);
		}

	useEffect(() => {
		setMsgsForAdd({ error: null, success: null });
		setSuccessMsg("");
		console.log('loaded', props);
        if (props.meetingData) {
            displayCreatedModified(props.meetingData.id);
        }
	}, []);

	const cancel = () => {
		if (madeChanges && props.setOpenedAsk) {
			props.setOpenedAsk(true);
		} else {
			getMeetings();
			setTab("List");
			if (props.user) {
                if (props.user.type === 'A') {
                    if (setUserType) setUserType('own');
                }
            }
		}
	};

	const getTabIndex = () => {
		if (curTab === "Info") {
			return 0;
		}
		if (curTab === "Files") {
			return 1;
		}
	};

	const choose = (the_contact_data) => {
		console.log('choosing', the_contact_data);
		const newObj = clone(data.other_data);
		newObj[peopleType] = data.other_data[peopleType].concat({
			id: the_contact_data.id,
			name: createTheContactName(the_contact_data),
			email: the_contact_data.email
		})
		updateDataObj("other_data", newObj);
		setOpenedSearchModal(false);
	}

	let show_illi_owned_message = false;
    const user_logged_in = getUser();
    if (user_logged_in) {
        if (user_logged_in.type) {
            if (user_logged_in.type === 'A') {
                if (props.meetingData) {
                    if (props.meetingData.property_user_id === 1) {
                        show_illi_owned_message = true;
                    }
                }
            }
        }
    }

	return (
		<div>
			{props.showCloseBtn && (
				<div
					className="close-btn"
					onClick={() => {
						cancel();
					}}
				>
					<Close />
				</div>
			)}
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<h3 className="inline-block">
						{
							<div
								className="inline-block blue-text hoverable mr-20"
								onClick={() => {
									cancel();
								}}
							>
								<Tooltip title="Back to Meeting List" placement="top">
									<ArrowBack />
								</Tooltip>
							</div>
						}
						{action !== "Add" && (
							<div
								className={`${
									action === "View" ? "orange" : "lightgray"
								}-text inline-block mr-20 hoverable`}
								onClick={() => {
									setTab("View");
								}}
							>
								<Tooltip title="View Meeting" placement="top">
									<Visibility />
								</Tooltip>
							</div>
						)}
						{action !== "Add" && canEdit && (
							<div
								className={`${
									action === "Edit" ? "orange" : "lightgray"
								}-text inline-block mr-20 hoverable`}
								onClick={() => {
									setTab("Edit");
								}}
							>
								<Tooltip title="Edit Meeting" placement="top">
									<Edit />
								</Tooltip>
							</div>
						)}
						{action} Meeting{" "}
						{props.property_name ? (
							<span>
								for <strong>{`${props.property_name}`}</strong>
							</span>
						) : (
							""
						)}
					</h3>
					{action !== "Add" && canDelete && (
						<span
							onClick={() => {
								setOpenedModal(true);
							}}
							style={{
								marginLeft: 20,
								marginTop: 20,
								float: "right",
								fontSize: 11,
								fontWeight: "bold",
							}}
							className="red-text hoverable"
						>
							DELETE MEETING
						</span>
					)}
					{show_illi_owned_message && <div>
					    {<div><strong>illi Company Data</strong></div>}
                    </div>}
					<Dates 
						datesModified={datesModified} 
					/>
				</GridItem>
				{
					<Tabs
						TabIndicatorProps={{
							style: {
								background: "#da3b2f",
								borderBottom: "1px solid #da3b2f",
								color: "#da3b2f",
							},
						}}
						style={{ marginBottom: 10 }}
						value={getTabIndex()}
					>
						<Tab
							onClick={() => {
								setCurTab("Info");
							}}
							style={{
								color: getTabIndex() === 0 ? "#da3b2f" : "rgba(0, 0, 0, 0.87)",
							}}
							tabIndex={0}
							label="Meeting Info"
							icon={<i className="fas fa-info-circle font-22"></i>}
						/>
						{props.propertyAction !== 'Add' && <Tab
							onClick={() => {
								setCurTab("Files");
							}}
							style={{
								color: getTabIndex() === 1 ? "#da3b2f" : "rgba(0, 0, 0, 0.87)",
							}}
							tabIndex={1}
							label="Files"
							icon={<i className="fas fa-file font-22"></i>}
						/>}
					</Tabs>
				}
			</GridContainer>
			{curTab === "Info" && (
				<div
					className={
						action === "View" ? "no-click lightgray-bg enclosure" : "enclosure"
					}
				>
					<GridContainer>
						<GridItem xs={3} sm={3} md={4}>
							<div className="custom relative">
								{data.start_date && <div className="tiny-label">Start Date</div>}
								<Datetime
									inputProps={{
										placeholder: "Start Date",
									}}
									closeOnSelect={true}
									onChange={(e) => {
										if (typeof e === "object") {
											const stringDate = e.format("MM/DD/YYYY");
											return updateDataObjs({
												start_date: stringDate,
												end_date: stringDate
											});
										}
										updateDataObj("start_date", e);
									}}
									timeFormat={false}
									value={data.start_date ? moment(data.start_date) : ""}
								/>
							</div>
							<small className="gray-text">Start Time</small>
							{<div className="time-hover"
								onClick={() => {
									setOpenTime('start');
								}}
							>
									{data.start_time}
							</div>}
							{openTime === 'start' && <Time
								label="Start Time"
								open={true}
								close={() => {
									setOpenTime('');
								}}
								setTime={(e) => {
									updateDataObj("start_time", e);
									setOpenTime('');
								}}
								time={data.start_time}
							/>}
						</GridItem>
						<GridItem xs={3} sm={3} md={4}>
							<div className="custom relative">
								{data.end_date && <div className="tiny-label">End Date</div>}
								<Datetime
									inputProps={{
										placeholder: "End Date",
									}}
									closeOnSelect={true}
									onChange={(e) => {
										console.log('e', e);
										if (typeof e === "object") {
											const stringDate = e.format("MM/DD/YYYY");
											return updateDataObj("end_date", stringDate);
										}
										updateDataObj("end_date", e);
									}}
									timeFormat={false}
									value={data.end_date ? moment(data.end_date) : ""}
								/>
							</div>
							<small className="gray-text">End Time</small>
							{<div className="time-hover"
								onClick={() => {
									setOpenTime('end');
								}}
							>
									{data.end_time}
							</div>}
							{openTime === 'end' && <Time
								label="End Time"
								open={true}
								close={() => {
									setOpenTime('');
								}}
								setTime={(e) => {
									updateDataObj("end_time", e);
									setOpenTime('');
								}}
								time={data.end_time}
							/>}
						</GridItem>
						<GridItem xs={3} sm={3} md={4}></GridItem>
						<GridItem xs={3} sm={3} md={4}>
							<CustomSelect
								label="Outcome"
								options={meetingFields.outcome.options}
								choose={(e, n) => {
									updateDataObj("outcome", e);
								}}
								default={data.outcome}
							/>
						</GridItem>
						<GridItem xs={3} sm={4} md={4}>
							<div className="carded">
								<h4>{data.other_data.invitees.length} Invitee(s)</h4>
								{action !== "View" && (
									<Button
										color="primary"
										onClick={() => {
											setPeopleType("invitees");
											setOpenedSearchModal(true);
										}}
									>
										<i className="fa fa-plus"></i> Add Invitee
									</Button>
								)}
								<List>
									{data.other_data.invitees.map((a, i) => {
										return (
											<ListItem button key={`inv-${i}`}>
												<ListItemIcon>
													<Person />
												</ListItemIcon>
												<ListItemText inset primary={`${a.name} - ${a.email}`} />
												<ListItemSecondaryAction>
													<Delete
														className="hoverable red-text"
														onClick={() => {
															updateDataObj("other_data", {
																...data.other_data,
																invitees: data.other_data.invitees.filter(
																	(ag) => {
																		if (ag.id === a.id) {
																			return false;
																		}
																		return true;
																	}
																),
															});
														}}
													/>
												</ListItemSecondaryAction>
											</ListItem>
										);
									})}
								</List>
							</div>
						</GridItem>
						<GridItem xs={3} sm={4} md={4}></GridItem>
						<GridItem xs={3} sm={8} md={12}>
							<div className="custom-textfield">
								<TextField
									label="Notes"
									multiline
									rowsMax="4"
									value={data.notes}
									onChange={(e) => {
										updateDataObj("notes", e.target.value);
									}}
									className=""
									margin="normal"
									validate={getValidations("notes")}
								/>
							</div>
						</GridItem>
						<GridItem xs={3} sm={4} md={12}>
							<hr />
						</GridItem>
						{props.loadingMeeting && <Loader />}
						{!props.loadingMeeting && <div>
							{errorMsg && !successMsg && (
								<div className="red-text bold" style={{ margin: 20 }}>
									{errorMsg}
								</div>
							)}
						</div>}
						{(successMsg && !props.loadingMeeting) && (
							<div className="green-text bold" style={{ margin: 20 }}>
								{successMsg}
							</div>
						)}
						{((action === "Edit" || action === "Add") && !props.loadingMeeting) && (
							<GridItem xs={12} sm={12} md={12}>
								<Button
									color="primary"
									disabled={errorMsg ? true : false}
									onClick={() => {
										if (action === "Edit") {
											doUpdate();
										} else {
											doAdd();
										}
									}}
									className="mr-20"
								>
									{action === "Edit" ? "Update Meeting" : "Add New Meeting"}
								</Button>
								<Button
									color="white"
									onClick={() => {
										cancel();
									}}
								>
									CANCEL
								</Button>
							</GridItem>
						)}
					</GridContainer>

					{openedSearchModal && (
						<CustomDialog
							open={openedSearchModal}
							close={() => {
								setOpenedSearchModal(false);
							}}
							choose={choose}
							create_and_choose={choose}
							peopleType={peopleType}
							title={`Select ${peopleType}`}
							label={`Search ${peopleType}`}
							current=""
							email_is_required={true}
						/>
					)}
				</div>
			)}

			{curTab === "Files" && (
				<FilesForm
					meetingData={props.meetingData}
					fileList={props.fileList}
					getCrmFileList={props.getCrmFileList}
					getCrmFile={props.getCrmFile}
					deleteCrmFile={props.deleteCrmFile}
					createCrmFile={props.createCrmFile}
					contactSuccess={props.contactSuccess}
					contactError={props.contactError}
					contactLoading={props.contactLoading}
					downloadedFile={props.downloadedFile}
					formDataObj={formDataObj}
					setFormDataObj={setFormDataObj}
					action={action}
				/>
			)}

			{openedModal && (
				<div>
					<MeetingModal
						open={openedModal}
						close={() => {
							setOpenedModal(false);
						}}
						data={data}
						setTab={setTab}
						error={error}
						success={success}
						prevSuccess={prevSuccess}
						prevError={prevError}
						deleteMeeting={deleteMeeting}
						meetingID={data.id}
						getMeetings={getMeetings}
					/>
				</div>
			)}

			{notification && <Notification 
				open={notification ? true : false}
				close={() => {
					setNotification('');
				}}
				message={notification}
			/>}
		</div>
	);
};

const mapStateToProps = state => {
	const p = state.crm_properties;
	return {
		loadingMeeting: p.loadingMeeting,
	}
}

export default connect(mapStateToProps, null)(MeetingForm);
