import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import ReactTable from 'react-table';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { FadeLoader } from 'react-spinners';
import { formatDate2, clone } from 'shared/utility';
import LeaseForm from "./LeaseForm";
import { initialLeaseData } from './LeaseModel';
import CustomDialogWithProperty from 'components/CustomDialog/CustomDialogWithProperty';

import Dialog from '@material-ui/core/Dialog';
import CustomDialogClose from 'components/CustomDialog/CustomDialogClose';
import { getUser } from '../../../../shared/authValidation';
import Button from '../../../../components/CustomButtons/Button';

const LeaseMain = (props) => {
    const [tab, setTab] = useState('List');
    const [mainData, setMainData] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [openedPropertyModal, setOpenedPropertyModal] = useState(false);
    const [openedAsk, setOpenedAsk] = useState(false);
    const [user, setUser] = useState(null);
    const [userType, setUserType] = useState('own');


    useEffect(() => {
        setErrorMsg(props.error);
    }, [props.error]);

    useEffect(() => {
        setSuccessMsg(props.success);
    }, [props.success]);

    useEffect(() => {
        props.getSubProperties();
        const userPerms = getUser();
        if (userPerms) {
            setUser(userPerms);
        }
    }, []);

    let allowIlliDBAccess = false;
    if (user) {
        if (user.type !== 'A') {
            allowIlliDBAccess = true;
        } else {
            if (user.permObj) {
                if (user.permObj.hasOwnProperty('CRM agent_access_illi_db') || user.permObj.hasOwnProperty('CRM agent_access_illi_db_full')) {
                    allowIlliDBAccess = true;
                }
            }
        }   
    }

    const data = props.subProperties ? props.subProperties.map((l, key) => { // map users to table rows
        return ({
            id: l.id,
            property_name: l.name,
            start_date: l.start_date ? formatDate2(l.start_date) : '',
            expiration_date: l.expiration_date ? formatDate2(l.expiration_date) : '',
            expiration_alert_date: l.expiration_alert_date ? formatDate2(l.expiration_alert_date) : '',
            mainData: l,
            placeholder: ''
        })
    }) : [];

    const columns = [
        {
            Header: "Lease ID #",
            accessor: "id"
        },
        {
            Header: "Property Name",
            accessor: "property_name"
        },
        {
            Header: "Lease Start Date",
            accessor: "start_date"
        },
        {
            Header: "Lease Expiration Date",
            accessor: "expiration_date"
        },
        {
            Header: "Lease Expiration Alert Date",
            accessor: "expiration_alert_date"
        },
        {
            Header: "",
            accessor: "placeholder"
        }
    ]

    const table = <div className="table-adjust2">
        <ReactTable
            filterable
            className='-striped -highlight'
            showPaginationTop
            showPaginationBottom
            resizable={false}
            defaultPageSize={10}
            pageSizeOptions={[10, 20, 30]}
            data={data}
            columns={columns}
            getTrProps={(state, rowInfo) => {
                if (rowInfo && rowInfo.row) {
                    return {
                        onClick: (e) => {
                            if (rowInfo) {
                                if (rowInfo.original) {
                                    const theMainData = rowInfo.original.mainData;
                                    setMainData(theMainData)
                                    if (theMainData.property_access === 'R') {
                                        setTab('View');
                                    }
                                    return setTab('Edit');
                                }
                            }
                        }
                    }
                } else {
                    return {}
                }
            }}
        />
    </div>

    let canEdit = mainData ?
        mainData.property_access === undefined ?
            true
            :
            mainData.property_access === 'R' ?
                false
                :
                true
        :
        true;

    let canDelete = mainData ?
        mainData.property_access === undefined ?
            true
            :
            mainData.property_access === 'R' || mainData.property_access === 'E' ?
                false
                :
                true
        :
        true;

    // if user is Agent and current tab is illi = ReadOnly
    let readOnly = false;
    if (user) {
        if (user.type !== 'E') {
            if (userType === 'illi') {
                canDelete = false;
                canEdit = false;
                readOnly = true;
            }
        }
    }

    const showForm = (tab === 'View' || (tab === 'Add' && mainData) || tab === 'Edit');

    const filterSpecial = (dat) => {
        if (user) {
            if (user.type === 'A' && dat) {
                
                return dat.filter(d => {
                    if (d.user_id === 1) return false;
                    return true;
                })
            }
        }
        return dat;
    }

    const choose = (chosen) => {
        // open property
        const md = clone(initialLeaseData);
        md.property_id = chosen.id;
        md.property_name = chosen.name;
        setMainData(md);
        setOpenedPropertyModal(false);
        setTab('Add');
    };

    return (
        <Card>
            <CardHeader color="rose" icon>
                <CardIcon
                    color={tab === 'Add' ? 'success' : 'rose'}
                    onClick={() => {
                        setOpenedPropertyModal(true);
                        setTab('Add');
                    }}
                    className="hovered hovered-shadow"
                >
                    <i className="fas fa-plus"></i> <strong>Add Lease</strong>
                </CardIcon>
            </CardHeader>
            <CardBody>
                <h1>Leases</h1>
                <hr />
                {user && <div>
                    {user.type !== 'E' && <div>
                        {allowIlliDBAccess && <Button 
                            onClick={() => { 
                                props.getSubProperties(true);
                                setUserType('illi');
                            }} 
                            style={{marginRight:20}} 
                            color={userType === 'illi' ? 'rose' : 'white'}
                        >ILLI DB</Button>}
                        <Button 
                            onClick={() => { 
                                props.getSubProperties(); 
                                setUserType('own');
                            }} 
                            color={userType === 'own' ? 'rose' : 'white'}
                        >MY DB</Button>
                    </div>}
                </div>}
                <hr />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        {(props.loading || !props.subProperties) && <div className="text-center">
                            <div style={{ margin: '0 auto', display: 'inline-block' }}><FadeLoader /></div>
                        </div>}
                        {(tab === 'List' && props.subProperties) && <div>
                            {props.subProperties.length > 0 && table}
                            {props.subProperties.length === 0 && <div>No Leases found</div>}
                        </div>}
                        {showForm &&
                            <Dialog
                                open={showForm ? true : false}
                                keepMounted
                                maxWidth={false}
                                fullWidth={true}
                                fullScreen={true}
                            >
                                <div className="dialog-wrapper">
                                    <LeaseForm
                                        leaseData={mainData}
                                        action={readOnly ? 'View' : tab}
                                        setTab={(t) => {
                                            if (t === 'List') {
                                                props.getSubProperties();
                                            }
                                            setTab(t);
                                        }}
                                        canEdit={canEdit}
                                        canDelete={canDelete}
                                        getContacts={props.getContacts}
                                        contactsData={props.contactsData}

                                        updateLease={props.updateLease}
                                        createLease={props.createLease}
                                        deleteLease={props.deleteLease}
                                        getLeases={() => {
                                            //props.getLeases(mainData.id);
                                        }}
                                        setMsgsForAdd={(e) => {
                                            setSuccessMsg(e.success);
                                            setErrorMsg(e.error);
                                        }}
                                        propertyID={mainData.property_id}
                                        property_name={mainData.property_name}
                                        error={errorMsg}
                                        success={successMsg}
                                        contactLoading={props.contactLoading}

                                        setOpenedAsk={setOpenedAsk}
                                        showCloseBtn={true}

                                        fileList={props.fileList}
                                        downloadedFile={props.downloadedFile}
                                        createCrmFile={props.createCrmFile}
                                        getCrmFileList={props.getCrmFileList}
                                        getCrmFile={props.getCrmFile}
                                        deleteCrmFile={props.deleteCrmFile}
                                        contactSuccess={props.contactSuccess}
                                        contactError={props.contactError}
                                        user={user}
                                        setUserType={setUserType}
                                    />
                                </div>
                            </Dialog>
                        }

                        {openedAsk && <CustomDialogClose
                            ok_go={() => {
                                setTab('List');
                            }}
                            open={openedAsk}
                            close={() => {
                                setOpenedAsk(false);
                            }}
                        />}
                    </GridItem>
                </GridContainer>
            </CardBody>

            {openedPropertyModal && <CustomDialogWithProperty
                open={openedPropertyModal}
                close={() => {
                    setOpenedPropertyModal(false);
                }}
                choose={choose}
                create_and_choose={choose}
                title="Search Existing Property"
                label="Search Existing Property"
            />}
        </Card>
    )
}

const mapStateToProps = state => {
    const p = state.crm_properties;
    const c = state.crm_contacts;
    return {
        subProperties: p.subProperties,
        error: p.error,
        success: p.success,
        loading: p.loading,
        contactsData: c.contactsData,
        leasesData: p.leasesData,
        leaseData: p.leaseData,
        loading: p.loading,
        propertyNames: p.propertyNames,

        fileList: c.fileList,
        downloadedFile: c.downloadedFile,
        contactError: c.error,
        contactSuccess: c.success,
        contactLoading: c.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSubProperties: (is_illi) => {
            dispatch(actions.getSubProperties({
                single: 'lease',
                plural: 'leases'
            }, is_illi));
        },
        getLeases: (property_id) => {
            dispatch(actions.getLeases(property_id));
        },
        getLease: (id) => {
            dispatch(actions.getLease(id));
        },
        createLease: (data) => {
            dispatch(actions.createLease(data));
        },
        updateLease: (data) => {
            dispatch(actions.updateLease(data));
        },
        deleteLease: (id) => {
            dispatch(actions.deleteLease(id));
        },
        getContacts: (params) => {
            dispatch(actions.getContacts(params));
        },
        getPropertyNames: () => {
            dispatch(actions.getPropertyNames());
        },
        getCrmFileList: (data) => {
            dispatch(actions.getCrmFileList(data));
        },
        createCrmFile: (data) => {
            dispatch(actions.createCrmFile(data));
        },
        getCrmFile: (data) => {
            dispatch(actions.getCrmFile(data));
        },
        deleteCrmFile: (data) => {
            dispatch(actions.deleteCrmFile(data));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaseMain);