import React from "react";
import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import * as actions from 'store/actions';
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Person from "@material-ui/icons/Person";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import DateRange from "@material-ui/icons/DateRange";
import Business from "@material-ui/icons/Business";
import GridContainer from "components/Grid/GridContainer.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
import Delete from "@material-ui/icons/Delete";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ContactsFormGeneral from "./ContactsFormGeneral";
import ContactsFormAddresses from "./ContactsFormAddresses";
import ContactsFormPreferences from "./ContactsFormPreferences";
import ContactsFormInformation from "./ContactsFormInformation";
import ContactsFormAppointments from "./ContactsFormAppointments";
import ContactsFormReview from "./ContactsFormReview";
import ContactsFormCompanies from "./ContactsFormCompanies";
import ContactsSharing from "./ContactsSharing";
import ContactsProperties from "./ContactsProperties";
import FilesForm from "./FilesForm";
import contactFields from "./ContactsObj";
import { addressFields, initialAddressData, otherAddressFields, contactDataObj, addTypeDict } from "./ContactsObj";
import { getUserID, getUser } from "shared/authValidation";
import { formatDate2, clone } from "shared/utility";
import { Tabs, Tab } from "@material-ui/core";
import { useSaveContacts } from "./ContactsLogic";
import { get, has } from "lodash";
import CustomDialogCompanyView from "../../../components/CustomDialog/CustomDialogCompanyView";
import axios from "store/axios/axios-crm";
import { handleError } from "../../../shared/utility";

const getContactPermissions = (contactShares, dataObj, user_id, action, isEmployee, permsObj) => {
    if (user_id === dataObj.user_id) return true;
    if (dataObj.user_id === 1 && isEmployee) return true;
    let result = false;
    if (contactShares) {
        if (contactShares.length > 0) {
            for (let i = 0; i < contactShares.length; i++) {
                const cs = contactShares[i];
                if (cs.user_id === user_id) {
                    if (cs.access === "F") {
                        if (action === "delete") return true;
                        if (action === "edit") return true;
                    }
                    if (cs.access === "E") {
                        if (action === "edit") return true;
                    }
                }
            }
        }
    }
	if (permsObj) {
		if (permsObj.permObj) {
			if (permsObj.permObj.hasOwnProperty('CRM agent_access_illi_db_full')) {
				return true;
			}
		}
	}
	console.log('permsObj--', permsObj);
    return result;
};

const ContactsForm = (props) => {
	const [dataObj, setDataObj] = useState(clone(contactDataObj));
	const [addressData, setAddressData] = useState(clone(initialAddressData));
	const [partnersData, setPartnersData] = useState([]);
	const [appointmentsForAdd, setAppointmentsForAdd] = useState([]);
	const [companiesForAdd, setCompaniesForAdd] = useState([]);
	const [propertiesForAdd, setPropertiesForAdd] = useState([]);
	const [sharesForAdd, setSharesForAdd] = useState([]);
	const [currentTab, setCurrentTab] = useState('General');
	const [madeChanges, setMadeChanges] = useState(false);
	const [userId, setUserId] = useState(null);
	const [userPerms, setUserPerms] = useState(null);
	const [openedReview, setOpenedReview] = useState(false);
	const [openedEditCompany, setOpenedEditCompany] = useState(false);
	const [companyID, setCompanyID] = useState(null);
	const [entitiesData, setEntitiesData] = useState([]);
	const [dbasForAdd, setDbasForAdd] = useState([]);
	const [historyData, setHistoryData] = useState([]);

	console.log('historyData', historyData);

	const getCrmHistory = async (contact_id) => {
		try {
			const query = '/crm_history?type=contact&id='+contact_id;
			console.log('query', '/crm_history?type=contact&id='+contact_id);
			const crmHistories = await axios.get(query);
			setHistoryData(crmHistories.data);
		} catch(e) {
			handleError(e);
		}
	}

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    const prevSuccess = usePrevious(props.success);

    useSaveContacts({
        dataObj,
        addressData,
        partnersData,
        madeChanges,
        action: props.action,
        currentAddressData: props.addressData,
        currentPartnersData: props.partnersData,
        updateContact: props.updateContact,
    });

    const resetData = () => {
		const firstName = get(props.success_data, 'first_name', '');
		const lastName = get(props.success_data, 'last_name', '');
		const d_firstName = get(dataObj, 'first_name', '');
		const d_lastName = get(dataObj, 'last_name', '');
		// FIX to Reloading if entering data from other contact
		console.log('resetting data', firstName, lastName, d_firstName, d_lastName);
		if (firstName === d_firstName && d_lastName === d_lastName) {
			setDataObj(clone(contactDataObj));
			setAddressData(clone(initialAddressData));
			if (props.action === "Edit") {
				props.getContacts();
			}
			setPartnersData([]);
			setSharesForAdd([]);
			setCompaniesForAdd([]);
			setPropertiesForAdd([]);
			setAppointmentsForAdd([]);
			setDbasForAdd([]);
			if (props.action === 'Add' && props.dontCloseFormOnAdd && props.success_data && !props.fromCustomDialogs) {
				// Load contact in Edit mode
				window.location.href = `/crm/contacts?contact_id=${props.success_data.id}`;
			}
			if (!props.fromCustomDialogs) setTimeout(() => {
			    props.clearMessages();
			    setCurrentTab("General");
			}, 2000);
		}
		return;
    };

    // CURRENT CONTACT DATA
    useEffect(() => {
        const contactData = props.contactData;
		console.log('contactData changed', contactData)
        if (contactData) {
            if (contactData.first_name || contactData.last_name || contactData.company_name) {
                // set the values
                const newObj = {};
                Object.keys(contactData).forEach((field) => {
					if (field === "contact_type") {
                        const contactTypes = dataObj.contact_type ? dataObj.contact_type.split(",") : [];
                        contactTypes.push(contactData[field]);
                        newObj[field] = contactTypes.join(",");
                    } else if (contactData[field] === "1") {
                        newObj[field] = "Yes";
                    } else if (contactData[field] === "0") {
                        newObj[field] = "No";
                    } else {
                        newObj[field] = contactData[field];
                    }
                });
                setDataObj(newObj);
				console.log('setting data obj 1');
                props.getContactShares(contactData.id);
				getCrmHistory(contactData.id);
            } else {
				console.log('closing form 1');
                props.closeForm();
            }

			props.getAddresses(contactData.id)
        }
        props.clearMessages();
    }, [props.contactData]);

    // GET CURRENT ADDRESS DATA
    useEffect(() => {
        const contactData = props.contactData;
        const currentAddressData = props.addressData;

        if (currentAddressData) {
            const newAddressData = clone(initialAddressData);
            currentAddressData.forEach((ad) => {
                if (ad.type !== "O") {
                    const addType = addTypeDict[ad.type];
                    Object.keys(ad).forEach((field) => {
                        if (field !== "type") {
                            newAddressData[addType][field] = ad[field];
                        }
                    });
                } else {
                    // addType is Others
                    newAddressData.other.push(ad);
                }
            });

            setAddressData(newAddressData);
        } else {
            if (contactData) {
                if (contactData.id) {
                    props.getAddresses(contactData.id);
                }
            }
        }
    }, [props.addressData]);

    //GET CURRENT PARTNERS DATA
    useEffect(() => {
        const contactData = props.contactData;
        const currentPartnersData = props.partnersData;
        if (currentPartnersData) {
            setPartnersData(currentPartnersData);
        } else {
            if (contactData) {
                if (contactData.id) {
                    props.getPartnersData(contactData.id);
                }
            }
        }
    }, [props.partnersData]);

    useEffect(() => {
        if (!prevSuccess && props.success) {
            if (props.success.indexOf("Successfully created contact") !== -1) {
                resetData();
                setMadeChanges(false);
            }
        }
    }, [props.success]);

    useEffect(() => {
        const user_id = getUserID();
        setUserId(user_id);
        const userPermsObj = getUser();
        setUserPerms(userPermsObj);
        // for inserting contacts with default contact type e.g. used in Add and Insert Lessee
        if (props.peopleType) {
            // agents, lessees, lessors
            let contact_type = dataObj.contact_type;
            let addType = "";
            if (props.peopleType.indexOf("agents") !== -1) addType = "Real Estate Agent";
            if (props.peopleType.indexOf("owners") !== -1) addType = "Property Owner";
            if (props.peopleType.indexOf("lessors") !== -1) addType = "Property Owner";
            if (props.peopleType.indexOf("lessees") !== -1) addType = "Tenant";

            if (contact_type.indexOf(addType) === -1 && addType) {
                if (contact_type) {
                    contact_type = contact_type.split(",").concat([addType]).join(",");
                } else {
                    contact_type = addType;
                }
            }

            let contact_subtype = dataObj.contact_subtype;
            if (props.peopleType === "lessors") {
                if (contact_subtype.indexOf("Property Owner - Lessor") === -1) {
                    if (contact_subtype) {
                        contact_subtype = contact_subtype.split(",").concat(["Property Owner - Lessor"]).join(",");
                    } else {
                        contact_subtype = "Property Owner - Lessor";
                    }
                }
            }
            if (props.peopleType === "lessees") {
                if (contact_subtype.indexOf("Tenant") === -1) {
                    if (contact_subtype) {
                        contact_subtype = contact_subtype.split(",").concat(["Tenant"]).join(",");
                    } else {
                        contact_subtype = "Tenant";
                    }
                }
            }

            updateDataObj("contact_type", contact_type, [{ field: "contact_subtype", value: contact_subtype }]);
        }
		// automatically opens Companies tab if prefill has company_name - used for Google/Dialpad Importer
		if (props.showCompanyAssigned && props.prefill && props.action === 'Add') {
			if (props.prefill.company_name) {
				console.log('automatically opening Companies tab');
				if (props.setAction) props.setAction('Edit');
				//setCurrentTab('Companies');
			}
		}
		if (props.showCompanyAssigned && props.prefill && props.action === 'Edit') {
			if (props.prefill.company_name && canEdit) {
				console.log('automatically opening Companies tab');
				if (props.setAction) props.setAction('Edit');
			}
		}
		if (props.contactData) {
			if (props.contactData.id) {
				if (props.contactData.id) {
					props.getCompanyContacts(props.contactData.id);
				}
			}
		}
    }, []);

	useEffect(() => {
        const { prefill } = props;
        if (prefill && props.action === 'Add') {
            const newDataObj = clone(dataObj);
            Object.keys(prefill).forEach((field) => {
				if (field !== 'company_name') {
					if (has(newDataObj, field)) {
						if (field === "contact_type") {
							const contactTypes = dataObj.contact_type ? dataObj.contact_type.split(",") : [];
							contactTypes.push(prefill[field]);
							newDataObj[field] = contactTypes.join(",");
						} else {
							newDataObj[field] = prefill[field];
						}
					}
				}
            });
            setDataObj(newDataObj);
			console.log('prefilling', prefill, newDataObj);
        }
    }, [props.prefill]);

    const updatePartners = (field, value, partnerIndex) => {
        let partners = clone(partnersData);
        if (field === "delete") {
            return setPartnersData(
                partners.filter((p, i) => {
                    if (i === partnerIndex) return false;
                    return true;
                })
            );
        }
        if (partnerIndex !== -1 && partnerIndex !== undefined) {
            partners[partnerIndex][field] = value;
        } else {
            const newPartner = {
                id: "",
                email: "",
                first_name: "",
                last_name: "",
                phone: "",
                notes: "",
            };
            partners.push(newPartner);
        }
        setPartnersData(partners);
        setMadeChanges(true);
    };

    const updateAddress = (field, value, addType, addressIndex) => {
        const addObj = clone(addressData);
        if (field === "delete") {
            addObj.other = addObj.other.filter((a, i) => {
                if (i === addressIndex) {
                    return false;
                }
                return true;
            });
            return setAddressData(addObj);
        }
        if (addType !== "other") {
            addObj[addType][field] = value;
        } else {
            if (addressIndex !== -1 && addressIndex !== undefined) {
                addObj["other"][addressIndex][field] = value;
            } else {
                const newOtherAddress = clone(otherAddressFields);
                newOtherAddress.id = "";
                newOtherAddress[field] = value;
                addObj.other.push(newOtherAddress);
            }
        }
        setAddressData(addObj);
        setMadeChanges(true);
    };

    const updateDataObj = (field, value, arr) => {
        const newObj = clone(dataObj);
        newObj[field] = value;
        if (arr) {
            if (arr.length > 0) {
                arr.forEach((a) => {
                    newObj[a.field] = a.value;
                });
            }
        }
        setDataObj(newObj);
        setMadeChanges(true);
    };

    const updateAppointmentsForAdd = (val) => {
        setAppointmentsForAdd(val);
        setMadeChanges(true);
    };

    const updateCompaniesForAdd = (val) => {
        setCompaniesForAdd(val);
        setMadeChanges(true);
    };

    const updateSharesForAdd = (val) => {
        setSharesForAdd(val);
        setMadeChanges(true);
    };

    const original_name =
        props.action !== "Add"
            ? props.contactData
                ? props.contactData.first_name || props.contactData.last_name
                    ? `${props.contactData.first_name} ${props.contactData.last_name}`
                    : props.contactData.company_name
                : ""
            : "";

    const isEmployee = userPerms ? (userPerms.type ? (userPerms.type === "E" ? true : false) : false) : false;
    const canEdit = getContactPermissions(props.contactShares, dataObj, userId, "edit", isEmployee, userPerms);
    const canDelete = getContactPermissions(props.contactShares, dataObj, userId, "delete", isEmployee, userPerms);

    const getTabIndex = () => {
        if (props.action !== "Add") {
            const contactInfoArr = ["General", "Preferences", "Information", "Addresses", "Partners", "Submit"];
            if (contactInfoArr.indexOf(currentTab) !== -1) {
                if (props.action === "Add" && currentTab === "Submit") return 4;
                return 0;
            }
            if (currentTab === "Companies") {
                return 1;
            }
            if (currentTab === "Appointments") {
                return 2;
            }
            if (currentTab === "Properties") {
                return 3;
            }
            if (currentTab === "Share") {
                return 4;
            }
            if (currentTab === "Submit") {
                return 5;
            }
            if (currentTab === "Files") {
                return 6;
            }
        } else {
            const contactInfoArr = ["General", "Preferences", "Information", "Addresses", "Partners", "Submit"];
            if (contactInfoArr.indexOf(currentTab) !== -1) {
                if (currentTab === "Submit") return 4;
                return 0;
            }
			if (currentTab === "Companies") {
                return 1;
            }
			if (currentTab === "Properties") {
                return 2;
            }
            if (currentTab === "Share") {
                return 3;
            }
            if (currentTab === "Files") {
                return 4;
            }
            if (currentTab === "Submit") {
                return 5;
            }
        }
    };

    const cancel = () => {
        if (madeChanges && props.setOpenedAsk) {
            if (props.setSaveContactsFormValues) {
                props.setSaveContactsFormValues({
                    cfDataObj: dataObj,
                    cfAddressData: addressData,
                    cfPartnersData: partnersData,
                    madeChanges: madeChanges,
                });
            }
            props.setOpenedAsk(true);
        } else {
			console.log('closing form 2');
            props.closeForm();
        }
    };

	const theOriginal = props.contactData;
	let show_illi_owned_message = false;
	if (theOriginal) {
		if (theOriginal.user_id) {
			const the_user = getUser();
			const owner_id = theOriginal.user_id;
			if (the_user.type) {
				if (the_user.type === 'A') {
					if (owner_id) {
						if (owner_id === 1) {
							show_illi_owned_message = true;
						}
					}
				}
			}
		}
	}

	const created_date = historyData[0] ? ' - ' + historyData[0].name : '';
	const modified_date = historyData[historyData.length - 1] ? ' - ' + historyData[historyData.length - 1].name : '';

	console.log('ContactsForm', props, dataObj, companiesForAdd, propertiesForAdd);

	return (
		<div>
			{props.showCloseBtn && (
				<div
					className='close-btn'
					onClick={() => {
						cancel();
					}}
				>
					<Close />
				</div>
			)}
			<GridContainer>
				<GridItem xs={12} sm={7} md={7}>
					<h3 style={{ display: 'inline-block', marginRight: 40 }}>
						{
							<div
								style={{ marginRight: 20, marginBottom: 10 }}
								className='inline-block blue-text hoverable'
								onClick={() => {
									cancel();
								}}
							>
								<Tooltip id='tooltip-top-start1' title='Back to List' placement='top'>
									<ArrowBack />
								</Tooltip>
							</div>
						}
						{props.action !== 'Add' && (
							<div
								className={`${
									props.action === 'View' ? 'orange' : 'lightgray'
								}-text inline-block mr-20 hoverable`}
								onClick={() => {
									props.openForm('View', props.contactData);
								}}
							>
								<Tooltip id='tooltip-top-start1' title='View Only Mode' placement='top'>
									<Visibility />
								</Tooltip>
							</div>
						)}
						{props.action !== 'Add' && canEdit && (
							<div
								className={`${
									props.action === 'Edit' ? 'blue' : 'lightgray'
								}-text inline-block mr-20 hoverable`}
								onClick={() => {
									props.openForm('Edit', props.contactData);
								}}
							>
								<Tooltip id='tooltip-top-start2' title='Edit Mode' placement='top'>
									<Edit />
								</Tooltip>
							</div>
						)}
						{props.action !== 'Add' && canDelete && !props.noDelete && (
							<div
								className={`${
									props.action === 'Edit' ? 'blue' : 'lightgray'
								}-text inline-block mr-20 hoverable`}
								onClick={() => {
									props.openAskModal(dataObj);
								}}
							>
								<Tooltip id='tooltip-top-start2' title='Delete Contact' placement='top'>
									<Delete />
								</Tooltip>
							</div>
						)}
						{props.action === 'Add' ? 'Add Contact' : <strong>{original_name}</strong>}
					</h3>
					{show_illi_owned_message && <p>
						{<div><strong>illi Company Data</strong></div>}
					</p>}
					{(props.prefill && !dataObj.id && props.action === 'Add') && <div>
						{props.prefill.company_name && <div className="boxed">
							<h4>Imported Contact Data Applied</h4>
							<p>Click Companies Tab to Open and Add Company</p>
						</div>}
					</div>}
					{(props.prefill && dataObj.id) && <div className="boxed">
						<h4>Imported Contact Data</h4>
						{Object.keys(props.prefill).map((pf,i) => {
							var val = props.prefill[pf] ? props.prefill[pf].trim() : '';
							if (pf !== 'company_name') {
								let fieldLabel = pf.toUpperCase().replace('_', ' ');
								if (contactFields[pf]) {
									fieldLabel = contactFields[pf].label;
								}
								if (!props.prefill[pf]) return <div key={`${i}-prefill`} style={{marginBottom:15}}>
									{fieldLabel}: <strong>N/A</strong>
								</div>
								if (val !== dataObj[pf]) {
									return <div key={`${i}-prefill`} style={{marginBottom:15}}>
										{fieldLabel}: <strong>{val}</strong> <Button
											size="sm"
											style={{marginLeft:20}}
											onClick={() => {
												updateDataObj(pf, val.trim());
												props.setAction('Edit');
											}}
											color="rose"
										>COPY</Button> 
									</div>
								} else {
									return <div key={`${i}-prefill`} style={{marginBottom:15}}>
										{fieldLabel}: <strong>{val}</strong>
									</div>
								}
							} else {
								let companyAlreadyExists = false;
								if (props.prefill[pf]) {
									if (props.companyContactsData) {
										props.companyContactsData.forEach(cd => {
											const cm = cd.company_data;
											if (cm.name === props.prefill[pf]) {
												companyAlreadyExists = true;
											}	
										});
									}
								}
								if (props.prefill[pf] && !companyAlreadyExists) {
									return <div key={`${i}-prefill`} style={{marginBottom:15}}>
										Company: <strong>{props.prefill[pf]}</strong> <Button
												size="sm"
												style={{marginLeft:20}}
												onClick={() => {
													setCurrentTab('Companies');
													props.setAction('Edit');
												}}
												color="rose"
											>COPY</Button>
									</div>
								} else {
									return <div key={`${i}-prefill`} style={{marginBottom:15}}>
										Company: <strong>{props.prefill[pf]}</strong>
									</div>
								}
							}
						})}	
					</div>}
				</GridItem>
				<GridItem xs={12} sm={5} md={5}>
					{props.action !== 'Add' && (
						<small className='gray-text'>
							<strong className='blue-text'>Contact #:</strong>{' '}
							{props.contactData ? (props.contactData.illi_number ? '' : props.contactData.id) : ''}
							<br />
							<strong className='blue-text'>Date Created:</strong>{' '}
							{props.contactData ? formatDate2(props.contactData.created_date) + created_date : ''}
							<br />
							<strong className='blue-text'>Date Last Modified:</strong>{' '}
							{props.contactData ? formatDate2(props.contactData.modified_date) + modified_date : ''}
							{props.action !== 'View' && <Button
								color={'transparent'}
								size="lg"
								style={{
									display: 'flex',
									justifyContent: 'center',
									paddingLeft: '0px',
									marginTop: '10px'
								}}
								onClick={() => {
									props.clearMessages();
									setOpenedReview(true);
								}}
							>
								<span className="blue-text">
									<i className="fas fa-save mr-10"></i> Save
								</span>
							</Button>}
						</small>
					)}
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<br />
					{props.action !== 'Add' && (
						<Tabs
							TabIndicatorProps={{
								style: { background: '#da3b2f', borderBottom: '1px solid #da3b2f', color: '#da3b2f' },
							}}
							style={{ marginBottom: 10 }}
							value={getTabIndex()}
						>
							{
								<Tab
									onClick={() => {
										setCurrentTab('General');
									}}
									style={{ color: getTabIndex() === 0 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)' }}
									tabIndex={0}
									label='Contact Info'
									icon={<Person />}
								/>
							}
							{
								<Tab
									onClick={() => {
										setCurrentTab('Companies');
									}}
									style={{ color: getTabIndex() === 1 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)' }}
									tabIndex={1}
									label='Companies'
									icon={<PersonAddIcon />}
								/>
							}
							{props.action !== 'Add' && (
								<Tab
									onClick={() => {
										setCurrentTab('Appointments');
									}}
									style={{ color: getTabIndex() === 2 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)' }}
									tabIndex={2}
									label='Appointments'
									icon={<DateRange />}
								/>
							)}
							{
								<Tab
									tabIndex={3}
									style={{
										color: getTabIndex() === 3 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)',
										display: props.action !== 'Add' && !props.noDelete ? 'block' : 'none',
									}}
									label='Properties'
									onClick={() => {
										setCurrentTab('Properties');
									}}
									icon={<Business />}
								/>
							}
							{
								<Tab
									tabIndex={4}
									style={{
										color: getTabIndex() === 4 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)',
										display: props.action === 'Add' || canDelete ? 'block' : 'none',
									}}
									label='Share Contact'
									onClick={() => {
										setCurrentTab('Share');
									}}
									icon={<i className='fas fa-handshake font-22'></i>}
								/>
							}
							{
								<Tab
									tabIndex={5}
									style={{
										color: getTabIndex() === 5 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)',
										display: props.action === 'Add' || canDelete ? 'block' : 'none',
									}}
									label='Files'
									onClick={() => {
										setCurrentTab('Files');
									}}
									icon={<i className='fas fa-file font-22'></i>}
								/>
							}
							{
								<Tab
									tabIndex={6}
									style={{
										color: getTabIndex() === 6 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)',
										display: props.action === 'Add' ? 'block' : 'none',
									}}
									label='Save'
									onClick={() => {
										setOpenedReview(true);
									}}
									icon={<i className='fas fa-save font-22'></i>}
								/>
							}
						</Tabs>
					)}
					{props.action === 'Add' && (
						<Tabs
							TabIndicatorProps={{
								style: { background: '#da3b2f', borderBottom: '1px solid #da3b2f', color: '#da3b2f' },
							}}
							style={{ marginBottom: 10 }}
							value={getTabIndex()}
						>
							{
								<Tab
									onClick={() => {
										setCurrentTab('General');
									}}
									style={{ color: getTabIndex() === 0 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)' }}
									tabIndex={0}
									label='Contact Info'
									icon={<Person />}
								/>
							}
							{
								<Tab
									onClick={() => {
										setCurrentTab('Companies');
									}}
									style={{ color: getTabIndex() === 1 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)' }}
									tabIndex={1}
									label='Companies'
									icon={<PersonAddIcon />}
								/>
							}
							{
								<Tab
									tabIndex={2}
									style={{
										color: getTabIndex() === 2 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)'
									}}
									label='Properties'
									onClick={() => {
										setCurrentTab('Properties');
									}}
									icon={<Business />}
								/>
							}
							{
								<Tab
									tabIndex={3}
									style={{
										color: getTabIndex() === 3 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)',
										display: props.action === 'Add' || canDelete ? 'block' : 'none',
									}}
									label='Share Contact'
									onClick={() => {
										setCurrentTab('Share');
									}}
									icon={<i className='fas fa-handshake font-22'></i>}
								/>
							}
							{
								<Tab
									tabIndex={4}
									style={{
										color: getTabIndex() === 4 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)',
										display: props.action === 'Add' || canDelete ? 'block' : 'none',
									}}
									label='Files'
									onClick={() => {
										setCurrentTab('Files');
									}}
									icon={<i className='fas fa-file font-22'></i>}
								/>
							}
							{
								<Tab
									tabIndex={5}
									style={{
										color: getTabIndex() === 5 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)',
										display: props.action === 'Add' ? 'block' : 'none',
									}}
									label='Save'
									onClick={() => {
										setOpenedReview(true);
									}}
									icon={<i className='fas fa-save font-22'></i>}
								/>
							}
						</Tabs>
					)}
				</GridItem>
			</GridContainer>
			<form
				autoComplete='off'
				style={{ width: '100%', maxWidth: 1400 }}
				onSubmit={(e) => {
					e.preventDefault();
				}}
			>
				<GridContainer>
					<GridItem xs={12} sm={12} md={12}>
						{/* Tab Nav */}
						<div style={{ marginBottom: 20 }}>
							{['General', 'Preferences', 'Information', 'Addresses'].map((t) => {
								if (props.action === 'Add' && t === 'Submit') return null;
								if (props.action === 'View' && t === 'Submit') return null;
								if (
									currentTab === 'Appointments' ||
									currentTab === 'Companies' ||
									currentTab === 'Share' ||
									currentTab === 'Properties' ||
									currentTab === 'Files'
								)
									return null;
								return (
									<Button
										key={t}
										color={
											currentTab === t ? 'success' : t === 'Submit' ? 'transparent' : 'primary'
										}
										style={{ marginRight: 10 }}
										onClick={() => {
											if (t === 'Submit') {
												props.clearMessages();
												setOpenedReview(true);
											} else {
												props.clearMessages();
												setCurrentTab(t);
											}
											
										}}
									>
										{t === 'Submit' ? (
											<span className='blue-text'>
												<i className='fas fa-save mr-10'></i> Save
											</span>
										) : t === 'Addresses' ? (
											'Addresses & Other Info'
										) : (
											t
										)}
									</Button>
								);
							})}
						</div>
					</GridItem>
				</GridContainer>
				{currentTab === 'General' && (
					<ContactsFormGeneral
						dataObj={dataObj}
						originalContactData={props.contactData}
						contactOptions={props.contactOptions}
						updateDataObj={updateDataObj}
						action={props.action}
						peopleType={props.peopleType}
						crmAllowed={props.crmAllowed}
						fileList={props.fileList}
						getCrmFileList={props.getCrmFileList}
						createCrmFile={props.createCrmFile}
						deleteCrmFile={props.deleteCrmFile}
						contactData={props.contactData}
						getCrmFile={props.getCrmFile}
						downloadedFile={props.downloadedFile}
						loading={props.loading}
						success={props.success}
						error={props.error}
						formDataObj={props.formDataObj}
						setFormDataObj={props.setFormDataObj}
						setMadeChanges={setMadeChanges}
					/>
				)}
				{currentTab === 'Addresses' && (
					<ContactsFormAddresses
						addressData={addressData}
						originalContactData={props.contactData}
						updateAddress={updateAddress}
						otherAddressFields={otherAddressFields}
						addressFields={addressFields}
						initialAddressData={initialAddressData}
						action={props.action}
						getContacts={props.getContacts}
						getContact={props.getContact}
						contactsData={props.contactsData}
						contactData={props.retrievedContactData}
						updatePartners={updatePartners}
						setPartnersData={setPartnersData}
						partnersData={partnersData}
						entitiesData={entitiesData}
						setEntitiesData={setEntitiesData}
					/>
				)}
				{currentTab === 'Preferences' && (
					<ContactsFormPreferences dataObj={dataObj} updateDataObj={updateDataObj} action={props.action} />
				)}
				{currentTab === 'Information' && (
					<ContactsFormInformation dataObj={dataObj} updateDataObj={updateDataObj} action={props.action} />
				)}
				{currentTab === 'Appointments' && (
					<ContactsFormAppointments
						action={props.action}
						contactData={dataObj}
						canEdit={canEdit}
						appointmentsForAdd={appointmentsForAdd}
						setAppointmentsForAdd={updateAppointmentsForAdd}
						getContacts={props.get_contacts}
						contactsData={props.contactsData}
					/>
				)}
				{currentTab === 'Companies' && (
					<ContactsFormCompanies
						action={props.action}
						setAction={props.setAction}
						contactData={dataObj}
						canEdit={canEdit}
						canDelete={canDelete}
						companiesForAdd={companiesForAdd}
						setCompaniesForAdd={updateCompaniesForAdd}
						dbasForAdd={dbasForAdd}
						setDbasForAdd={setDbasForAdd}
						setOpenedEditCompany={setOpenedEditCompany}
						setCompanyID={setCompanyID}
						prefill={props.prefill}
						showCompanyAssigned={props.showCompanyAssigned}
					/>
				)}
				{currentTab === 'Properties' && (
					<ContactsProperties
						action={props.action}
						canEdit={canEdit}
						contactData={dataObj}
						propertiesForAdd={propertiesForAdd}
						setPropertiesForAdd={setPropertiesForAdd}
						updateContact={props.updateContact}
						updateDataObj={updateDataObj}
						dataObj={dataObj}
						setDataObj={setDataObj}
					/>
				)}
				{currentTab === 'Share' && (
					<ContactsSharing
						action={props.action}
						updateContact={props.updateContact}
						updateDataObj={updateDataObj}
						getContactShares={props.getContactShares}
						getContactUsernames={props.getContactUsernames}
						createContactShare={props.createContactShare}
						deleteContactShare={props.deleteContactShare}
						contactShares={props.contactShares}
						contactUsernames={props.contactUsernames}
						dataObj={dataObj}
						canEdit={canEdit}
						sharesForAdd={sharesForAdd}
						setSharesForAdd={updateSharesForAdd}
						success={props.success}
					/>
				)}
				{currentTab === 'Files' && (
					<FilesForm
						action={props.action}
						dataObj={dataObj}
						canEdit={canEdit}
						sharesForAdd={sharesForAdd}
						setSharesForAdd={updateSharesForAdd}
						getContactFiles={props.getContactFiles}
						getCrmFileList={props.getCrmFileList}
						fileList={props.fileList}
						createCrmFile={props.createCrmFile}
						deleteCrmFile={props.deleteCrmFile}
						getCrmFile={props.getCrmFile}
						contactData={props.contactData}
						downloadedFile={props.downloadedFile}
						success={props.success}
						error={props.error}
						loading={props.loading}
						formDataObj={props.formDataObj}
						setFormDataObj={props.setFormDataObj}
					/>
				)}
				{openedReview && props.action !== 'View' && (
					<div>
						<ContactsFormReview
							setOpenedReview={setOpenedReview}
							dataObj={dataObj}
							formDataObj={props.formDataObj}
							setFormDataObj={props.setFormDataObj}
							currentContactData={props.contactData}
							partnersData={partnersData}
							currentPartnersData={props.partnersData}
							addressData={addressData}
							currentAddressData={props.addressData}
							createContact={props.createContact}
							updateContact={props.updateContact}
							closeForm={cancel}
							closeFormFinal={() => {
								console.log('closing form 3')
								if (!props.dontCloseFormOnAdd) {
									props.closeForm();
								}
							}}
							close={props.close}
							action={props.action}
							success={props.success}
							theContactData={props.theContactData}
							error={props.error}
							appointmentsForAdd={appointmentsForAdd}
							companiesForAdd={companiesForAdd}
							dbasForAdd={dbasForAdd}
							propertiesForAdd={propertiesForAdd}
							sharesForAdd={sharesForAdd}
							entitiesData={entitiesData}
							setMadeChanges={setMadeChanges}
							choose={props.choose}
						/>
					</div>
				)}
			</form>
			{madeChanges && (
				<div className='bottom-dweller text-center'>
					<div>Change(s) Detected, please make sure to save your contact.</div>
				</div>
			)}
			{openedEditCompany && (
				<CustomDialogCompanyView
					open={openedEditCompany}
					close={() => {
						setOpenedEditCompany(false);
					}}
					companyID={companyID}
				/>
			)}
		</div>
	);
};

const mapStateToProps = state => {
    const c = state.crm_companies
    return {
        companyContactsData: state.crm_companies.companyContactsData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCompanyContacts: (contact_id) => {
            dispatch(actions.getCompanyContacts({contact_id}));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactsForm);
