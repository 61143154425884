import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "../../components/CustomSelect/CustomSelect.jsx";
import { InputLabel, FormControlLabel, Checkbox, FormGroup } from '@material-ui/core';
const clone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

const updateObj = (obj, field, value) => {
    const newCustomData = clone(obj);
    newCustomData[field] = value;
    return newCustomData;
}

// will_need_workstation: '', // Yes 
// will_need_microsoft_office: '', // Yes
// will_need_adobe_acrobat: '', // Yes, No 
// will_need_physical_phone: '', // Yes, No 
// needs_access_to_the_following_printers: '',
// Lexmark Brokerage, Lexmark Property Management, Lexmark HR

const UserAdminIT = (props) => {
    const setCustomData = props.setCustomData;
    const customData = props.customData;

    const update = (field, value) => {
        const newObj = updateObj(customData, field, value);
        console.log('newObj', newObj);
        setCustomData(newObj);
    }

    return (
        <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
                {/* <CustomRadio
                    name="will_need_workstation"
                    label="Will need workstation?,Yes,No"
                    options={['Yes','No']}
                    onChange={(e) => {
                        update('will_need_workstation', e.target.value);
                    }}
                    value={customData.will_need_workstation}
                  /> */}
                {/* <CustomRadio
                    name="will_need_microsoft_office"
                    label="Will need Microsoft Office?,Yes,No"
                    options={['Yes','No']}
                    onChange={(e) => {
                        update('will_need_microsoft_office', e.target.value);
                    }}
                    value={customData.will_need_microsoft_office}
                  /> */}
                {/* <CustomSelect
                    label="Needs access to the following printers?"
                    options={['Lexmark Brokerage', 'Lexmark Property Management', 'Lexmark HR']}
                    choose={(e, n) => {
                        
                        update('needs_access_to_the_following_printers', e);
                    }}
                    default={customData.needs_access_to_the_following_printers}
                /> */}
                {/* <CustomMultipleSelect
                    options={[
                        'Lexmark Brokerage', 
                        'Lexmark Property Management',
                        'LP - Property Management',
                        'Lexmark HR',
                        'Canon - Property Management'
                    ]}
                    label="Needs access to the following printers?"
                    choose={(e, n) => {
                        console.log('choosing the following printers', e);
                        update('needs_access_to_the_following_printers', e.join(','));
                    }}
                    defaultValues={customData.needs_access_to_the_following_printers ? customData.needs_access_to_the_following_printers.split(',') : []}
                /> */}
                <InputLabel style={{ fontSize: "14px" }}>Need access to the following printers?</InputLabel>
                <FormGroup
                    onChange={(e) => {
                        let arr = customData.needs_access_to_the_following_printers ? 
                        typeof customData.needs_access_to_the_following_printers === 'string' ?
                            customData.needs_access_to_the_following_printers.split(',') 
                        :
                            customData.needs_access_to_the_following_printers
                    : 
                        [];
                        if (arr.indexOf(e.target.name) === -1) {
                            arr.push(e.target.name);
                            update('needs_access_to_the_following_printers', arr.join(','));
                        } else {
                            update('needs_access_to_the_following_printers', arr.filter(a => {
                                if (a !== e.target.name) return true
                            }));
                        }
                    }}
                >
                    {[
                        'Lexmark Brokerage', 
                        'Lexmark Property Management',
                        'LP - Property Management',
                        'Lexmark HR',
                        'Canon - Property Management',

                        'Samsung - PM01',
                        'Samsung - PM02',
                        // 'Lexmark - HR', 
                        'Lexmark - Administration', 
                        'Canon - Brokerage', 
                        'HP - Property Management', 
                        'Epson - PM Budgets'
                    ].sort().map(m => {
                        return <FormControlLabel
                            control={<Checkbox color="default" name={m} checked={customData.needs_access_to_the_following_printers ? 
                                customData.needs_access_to_the_following_printers.includes(m) 
                            : 
                                false} />}
                            label={m}
                            key={m}
                        />
                    })}
                </FormGroup>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                {/* <CustomRadio
                    name="will_need_adobe_acrobat"
                    label="Will need Adobe Acrobat?,Yes,No"
                    options={['Yes','No']}
                    onChange={(e) => {
                        update('will_need_adobe_acrobat', e.target.value);
                    }}
                    value={customData.will_need_adobe_acrobat}
                  /> */}
                <CustomSelect
                    label="PDF Software"
                    options={['','Acrobat Reader', 'Acrobat Pro', 'PDF Complete', 'Other']}
                    choose={(e, n) => {
                        update('will_need_adobe_acrobat', e);
                    }}
                    default={customData.will_need_adobe_acrobat}
                />
                {/* <CustomRadio
                    name="will_need_physical_phone"
                    label="Will need physical phone?,Yes,No"
                    options={['Yes','No']}
                    onChange={(e) => {
                        update('will_need_physical_phone', e.target.value);
                    }}
                    value={customData.will_need_physical_phone}
                  /> */}
            </GridItem>
        </GridContainer>
    );
}

export default UserAdminIT;